import { useMutation } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { Alert } from '~/components/ui/alert';
import { Link } from 'react-router';
import { toast } from 'react-toastify';
import { gql } from '~/apollo/client-v3';
import type {
  CreateSavedDataSearchMutation,
  CreateSavedDataSearchMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { FormikField } from '~/components/common/FormikField';
import { Modal } from '~/components/common/Modal';
import { Button } from '~/components/ui/button';
import { useModalState } from '~/hooks/modal';
import { savedDataSearchRoute } from '~/paths';
import type { FormikSubmitHandler } from '~/utils/forms';
import type {
  DataSearchFormValues,
  SavedDataSearchFormValuesCreate,
} from '~/utils/modules/dataSearch';
import { toSavedDataSearchInput } from '~/utils/modules/dataSearch';
import { yup } from '~/utils/validation';
import { SavedDataSearchFormFields } from './savedDataSearch/SavedDataSearchFormFields';

const CREATE_SAVED_DATA_SEARCH = gql`
  mutation CreateSavedDataSearch($sdsParams: CreateSavedDataSearchInput!) {
    createSavedDataSearch(savedDataSearch: $sdsParams) {
      id
      name
    }
  }
`;

type Props = {
  children: (showModal: () => void) => JSX.Element;
  dataSearch: DataSearchFormValues;
};

const CreateSavedDataSearchModal: React.FC<Props> = ({
  children,
  dataSearch,
}) => {
  const { show, showModal, hideModal } = useModalState();

  const [saveDataSearch, { data, loading, error }] = useMutation<
    CreateSavedDataSearchMutation,
    CreateSavedDataSearchMutationVariables
  >(CREATE_SAVED_DATA_SEARCH, {});

  const handleCreate: FormikSubmitHandler<
    SavedDataSearchFormValuesCreate
  > = async (values, helpers) => {
    try {
      await saveDataSearch({
        variables: {
          sdsParams: toSavedDataSearchInput(values, dataSearch),
        },
      });
      toast.success('Data bookmarked successfully.');
      helpers.resetForm();
    } catch (err) {
      console.log('Error creating saved data search', err);
      toast.error(
        'There was a problem bookmarking the data. Please ensure you have all required fields filled in.',
      );
    }
  };

  const createdSds = data?.createSavedDataSearch;

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal}>
        <Formik
          onSubmit={handleCreate}
          initialValues={{
            name: '',
            note: '',
            isCompany: false,
          }}
          validationSchema={yup.object({
            name: yup.string().required('a name is required'),
            note: yup.string().optional(),
            isCompany: yup.boolean().required(),
          })}
        >
          <Form>
            <Modal.Body heading="Bookmark Data">
              <div className="space-y-4">
                <p>
                  Bookmarking this data will remember the selected filters and a
                  snapshot of the resulting measurements.
                </p>

                <SavedDataSearchFormFields mode="create" />

                <Field
                  name="isCompany"
                  label={
                    <>
                      <strong>Share with company</strong>
                      <br />
                      <small className="text-muted">
                        Save to My Company's Data Bookmarks, to be shared with
                        members of your company.
                      </small>
                    </>
                  }
                  component={FormikField}
                  type="checkbox"
                />

                {createdSds && (
                  <Alert status="success">
                    <div className="space-y-2">
                      <p>
                        Data bookmarked successfully! It can be viewed at the
                        following link:
                      </p>
                      <p>
                        <Link
                          to={savedDataSearchRoute(createdSds.id)}
                          target="_blank"
                          className="link"
                        >
                          {createdSds.name}
                        </Link>{' '}
                        (opens in new window)
                      </p>
                    </div>
                  </Alert>
                )}

                <FormErrors graphQLError={error} />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                type="button"
                color="ghost"
                onClick={hideModal}
                disabled={loading}
              >
                Close
              </Button>
              <Button type="submit" color="primary" loading={loading}>
                Bookmark Data
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

export default CreateSavedDataSearchModal;
