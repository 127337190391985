import { faExternalLink, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import { EnabledIndicator } from '~/components/common/icons/EnabledIndicator';
import { GeologyTypeIcon } from '~/components/common/icons/GeologyTypeIcon';
import {
  OutcropApprovalIcon,
  OutcropQCIcon,
} from '~/components/upload/outcrop/outcrop-icons';
import * as routes from '~/paths';
import type { SortableOutcrop } from '~/utils/modules/outcrop';

type Props = {
  outcrop: SortableOutcrop;
};

export function UploadOutcropListItem({ outcrop }: Props) {
  return (
    <tr>
      <td className="text-center">
        <OutcropApprovalIcon approvalState={outcrop.approvalState} />
      </td>
      <td>{outcrop.id}</td>
      <td>
        <Link to={routes.uploadOutcropUpdateRoute(outcrop.id)} className="link">
          {outcrop.name}
        </Link>
      </td>
      <td className="space-x-1">
        {outcrop.geologyType.map(gt => (
          <div key={gt} className="inline-block w-5 h-5">
            <GeologyTypeIcon geologyType={gt} hideTooltip />
          </div>
        ))}
      </td>
      <td>
        <Link
          to={routes.uploadRegionUpdateRoute(outcrop.region.id)}
          className="link"
        >
          {outcrop.region.name}
        </Link>
      </td>
      <td>{outcrop.region.location.country}</td>
      <td className="text-center">
        <Link
          to={routes.uploadOutcropUpdateStudiesRoute(outcrop.id)}
          className="link"
        >
          {outcrop.numStudies}
        </Link>
      </td>

      <td className="text-center">
        <EnabledIndicator value={outcrop.hasPaleomaps} />
      </td>

      <td className="text-center">
        <Link
          to={routes.uploadOutcropUpdateVirtualOutcropsRoute(outcrop.id)}
          className="link"
        >
          {outcrop.numVoms}
        </Link>
      </td>

      <td className="text-center">
        <OutcropQCIcon qcCompleted={outcrop.qcCompleted} />
      </td>

      <td className="text-right">
        <div className="join">
          <Link
            to={routes.uploadOutcropUpdateRoute(outcrop.id)}
            className="btn btn-sm btn-ghost gap-1 join-item"
          >
            <FontAwesomeIcon icon={faPencil} /> Edit
          </Link>

          <DropdownMenu color="ghost" size="sm" join>
            <DropdownMenu.Items>
              <DropdownMenu.Item>
                <Link
                  to={routes.outcropRoute(outcrop.id)}
                  target="_blank"
                  className="gap-1"
                >
                  View <FontAwesomeIcon icon={faExternalLink} />
                </Link>
              </DropdownMenu.Item>
            </DropdownMenu.Items>
          </DropdownMenu>
        </div>
      </td>
    </tr>
  );
}
