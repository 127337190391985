import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';
import { useState } from 'react';
import { useParams } from 'react-router';
import invariant from 'tiny-invariant';
import { videoParts } from '~/apollo/fragments';
import type {
  UploadOutcropVideosPageQuery,
  UploadOutcropVideosPageQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { ExpandedIcon } from '~/components/common/icons/ExpandedIcon';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { CreateVideoForm } from '~/components/supportingObject/urlBasedSO/CreateVideoForm';
import {
  UrlBasedSOList,
  urlBasedSoListPlacementParts,
} from '~/components/supportingObject/urlBasedSO/UrlBasedSOList';

const UPLOAD_OUTCROP_VIDEOS_PAGE = gql`
  query UploadOutcropVideosPage($id: Int!) {
    outcropList(id: $id) {
      id
      videos {
        ...videoParts
        placement {
          ...urlBasedSoListPlacementParts
        }
      }
    }
  }

  ${videoParts}
  ${urlBasedSoListPlacementParts}
`;

export default function UploadOutcropVideosPage() {
  const params = useParams();
  invariant(params.outcropId);
  const outcropId = parseInt(params.outcropId);

  const [isCreating, setIsCreating] = useState(false);

  const queryVars = { id: outcropId };

  const { data, loading } = useQuery<
    UploadOutcropVideosPageQuery,
    UploadOutcropVideosPageQueryVariables
  >(UPLOAD_OUTCROP_VIDEOS_PAGE, { variables: queryVars });

  const refetchQueries: [
    PureQueryOptions<UploadOutcropVideosPageQueryVariables>,
  ] = [{ query: UPLOAD_OUTCROP_VIDEOS_PAGE, variables: queryVars }];

  const outcrop = data?.outcropList.find(oc => oc.id === outcropId);
  const videos = R.sortBy(v => v.name, outcrop?.videos ?? []);

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <div className="space-y-4">
      <Panel>
        <Panel.Heading>
          <button
            type="button"
            onClick={() => setIsCreating(!isCreating)}
            className="w-full flex justify-between gap-6 items-center"
          >
            <Panel.Title>Create Video</Panel.Title>
            <ExpandedIcon expanded={isCreating} />
          </button>
        </Panel.Heading>

        {isCreating && (
          <Panel.Body>
            <CreateVideoForm
              outcropId={outcropId}
              refetchQueries={refetchQueries}
              onCreateSuccess={() => setIsCreating(false)}
            />
          </Panel.Body>
        )}
      </Panel>

      {!videos.length && <p>No videos created yet.</p>}

      <UrlBasedSOList
        items={videos}
        geologyTypes={[]}
        refetchQueries={refetchQueries}
      />
    </div>
  );
}
