import React from 'react';
import { Button } from '~/components/ui/button';

type IconSelectorProps = {
  value: string;
  onChange: (value: string) => any;
};

// pngs located in assets/images/so-icons
export const iconFileNames = [
  '360',
  'Depositional Setting',
  'Doc',
  'Map',
  'MiniModel',
  'Photo',
  'SediLog',
  'Video',
  'Weblink',
  'WellLog',
  'XSection',
  'Marker',
] as const;

function IconButton({
  icon,
  onClick,
  active,
}: {
  icon: string;
  onClick: (event: React.MouseEvent, icon: string) => any;
  active: boolean;
}) {
  return (
    <Button
      type="button"
      color={active ? 'warning' : 'ghost'}
      size="sm"
      onClick={e => {
        onClick(e, icon);
      }}
      className="px-1 py-0"
    >
      <img
        src={`/assets/images/so-icons/${icon}.svg`}
        alt={icon}
        height={50}
        width={50}
      />
    </Button>
  );
}

export function IconSelector({ value, onChange, ...props }: IconSelectorProps) {
  const handleClick = (event: React.MouseEvent, icon: string) => {
    event.preventDefault();
    onChange(icon);
  };

  return (
    <div {...props}>
      <p>Icon Selector</p>
      {iconFileNames.map(icon => (
        <IconButton
          key={icon}
          icon={icon}
          // onClick={handleClick(letter)}
          onClick={handleClick}
          active={value === icon}
        />
      ))}
    </div>
  );
}
