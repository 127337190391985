import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '~/components/ui/button';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import type {
  DeletePageMutation,
  DeletePageMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { Confirm } from '~/components/common/Confirm';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import UpdatePageForm from '~/components/upload/page/UpdatePageForm';
import { adminPageRoute } from '~/paths';
import { useUploadPageContext } from '~/routes/admin/page';

const DELETE_PAGE = gql`
  mutation DeletePage($id: Int!) {
    deletePage(id: $id)
  }
`;

export default function AdminPageUpdate() {
  const params = useParams();
  const pageId = parseInt(params.pageId ?? '-1');
  const { pageList, refetchQueries } = useUploadPageContext();
  const page = pageList.find(p => p.id === pageId);
  if (!page) throw new Error('page_not_found');

  useBreadcrumb('routes/admin/page/$pageId', page?.title ?? 'Update Page');

  const navigate = useNavigate();
  const [deletePage, { loading: loadingDelete }] = useMutation<
    DeletePageMutation,
    DeletePageMutationVariables
  >(DELETE_PAGE, {
    variables: { id: page.id },

    refetchQueries,
  });

  async function handleDelete() {
    try {
      await deletePage();
      toast.success('Page deleted successfully.');
      navigate(adminPageRoute());
    } catch (err) {
      console.log('Error deleting page', err);
      toast.error('There was a problem deleting the page.');
    }
  }

  return (
    <>
      <UpdatePageForm page={page} />

      <Confirm
        onConfirm={handleDelete}
        text="The page will be permanently deleted. If it is embedded in the app, it may prevent that page from functioning."
        submitButtonColor="error"
        submitText="Delete Page"
      >
        {handleConfirm => (
          <div className="text-center mt-4">
            <Button
              type="button"
              color="ghost"
              size="sm"
              className="text-error"
              onClick={handleConfirm}
              disabled={loadingDelete}
            >
              <FontAwesomeIcon icon={faTrash} /> Delete Page
            </Button>
          </div>
        )}
      </Confirm>
    </>
  );
}
