import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import { cn } from '~/utils/common';

export type AlertProps = {
  status?: 'info' | 'success' | 'warning' | 'error';
  icon?: ReactNode;
} & ComponentPropsWithoutRef<'div'>;

export function Alert({
  status,
  icon,
  children,
  className,
  ...divProps
}: AlertProps) {
  return (
    <div
      {...divProps}
      role="alert"
      className={cn(
        'alert',
        {
          'alert-info': status === 'info',
          'alert-success': status === 'success',
          'alert-warning': status === 'warning',
          'alert-error': status === 'error',
        },
        className,
      )}
    >
      {icon && <div>{icon}</div>}
      {children}
    </div>
  );
}
