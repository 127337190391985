import { useQuery } from '@apollo/client';
import { Link } from 'react-router';
import { graphql } from '~/apollo/generated/v3';
import type { UploadVftOverviewPageQuery } from '~/apollo/generated/v3/graphql';
import { FilterSearch } from '~/components/common/FilterSearch';
import { GeologyTypeIcon } from '~/components/common/icons/GeologyTypeIcon';
import { PageHeading } from '~/components/common/PageHeading';
import { Panel } from '~/components/common/Panel';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { OutcropApprovalIcon } from '~/components/upload/outcrop/outcrop-icons';
import { useSortFilter } from '~/hooks/data';
import {
  uploadOutcropSOPlacementsRoute,
  uploadOutcropUpdateRoute,
  uploadRegionUpdateRoute,
} from '~/paths';
import { outcropApprovalState } from '~/utils/modules/outcrop';

const UPLOAD_VFT_OVERVIEW_PAGE = graphql(`
  query UploadVftOverviewPage {
    outcropList {
      id
      name
      geologyType
      region {
        id
        name
        location {
          country
        }
      }
      approved
      readyForApproval
      placedSupportingObjectCount
    }
  }
`);

type Outcrop = UploadVftOverviewPageQuery['outcropList'][number];

function toSortableOutcrop(oc: Outcrop) {
  return {
    id: oc.id,
    name: oc.name,
    geologyType: oc.geologyType,
    region: oc.region,
    approvalState: outcropApprovalState(oc),
    placedSOs: oc.placedSupportingObjectCount,
  };
}

export default function UploadVftOverviewPage() {
  useBreadcrumb('routes/upload/vft-overview', 'VFT Overview');

  const { data, loading } = useQuery(UPLOAD_VFT_OVERVIEW_PAGE);

  const outcrops = (data?.outcropList ?? []).map(toSortableOutcrop);
  const {
    items,
    sortIndicatorProps: siProps,
    filterSearchProps,
  } = useSortFilter(outcrops, 'name', 'name', 'upload-vft-overview');

  if (loading) {
    return (
      <SpinnerPlaceholder>
        Loading outcrops and their supporting objects...
      </SpinnerPlaceholder>
    );
  }

  return (
    <>
      <PageHeading>VFT Overview</PageHeading>

      <Panel>
        <Panel.Heading>
          <Panel.Title>
            Outcrop List{' '}
            <span className="text-muted text-sm">
              (<strong>{items.length}</strong> results displayed)
            </span>
          </Panel.Title>
        </Panel.Heading>

        <Panel.Body>
          <FilterSearch {...filterSearchProps} />

          <table className="table w-full">
            <thead>
              <tr>
                <th className="text-center">
                  <SortTrigger
                    colName="approvalState"
                    sortIndicatorProps={siProps}
                    filterable
                  >
                    Approval
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger colName="id" sortIndicatorProps={siProps}>
                    ID
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger colName="name" sortIndicatorProps={siProps}>
                    Name
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger
                    colName="geologyType"
                    sortIndicatorProps={siProps}
                    filterable
                  >
                    Geology Type
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger
                    colName="region.name"
                    sortIndicatorProps={siProps}
                  >
                    Region
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger
                    colName="region.location.country"
                    sortIndicatorProps={siProps}
                    filterable
                  >
                    Country
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger colName="placedSOs" sortIndicatorProps={siProps}>
                    Placed SOs
                  </SortTrigger>
                </th>
              </tr>
            </thead>

            <tbody>
              {items.map(outcrop => (
                <tr key={outcrop.id}>
                  <td className="text-center">
                    <OutcropApprovalIcon
                      approvalState={outcrop.approvalState}
                    />
                  </td>
                  <td>{outcrop.id}</td>
                  <td>
                    <Link
                      to={uploadOutcropUpdateRoute(outcrop.id)}
                      className="link"
                    >
                      {outcrop.name}
                    </Link>
                  </td>
                  <td>
                    <div className="space-x-1">
                      {outcrop.geologyType.map(gt => (
                        <GeologyTypeIcon
                          key={gt}
                          geologyType={gt}
                          className="h-4 w-4"
                        />
                      ))}
                    </div>
                  </td>
                  <td>
                    <Link
                      to={uploadRegionUpdateRoute(outcrop.region.id)}
                      className="link"
                    >
                      {outcrop.region.name}
                    </Link>
                  </td>
                  <td className="whitespace-nowrap">
                    {outcrop.region.location.country}
                  </td>
                  <td>
                    <Link
                      to={uploadOutcropSOPlacementsRoute(outcrop.id)}
                      className="link"
                    >
                      {outcrop.placedSOs}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Panel.Body>
      </Panel>
    </>
  );
}
