import { faFlag, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLoaderData } from 'react-router';
import { apolloClient } from '~/apollo/client-v3';
import { graphql } from '~/apollo/generated/v3';
import type { UploadMiniModelsPageQuery } from '~/apollo/generated/v3/graphql';
import { FilterSearch } from '~/components/common/FilterSearch';
import { EnabledIndicator } from '~/components/common/icons/EnabledIndicator';
import { PageHeading } from '~/components/common/PageHeading';
import { Panel } from '~/components/common/Panel';
import { SortTrigger } from '~/components/common/SortTrigger';
import { Tooltip } from '~/components/common/Tooltip';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { useSortFilter } from '~/hooks/data';
import {
  uploadOutcropUpdateMiniModelsRoute,
  uploadOutcropUpdateRoute,
} from '~/paths';
import { rejectNil } from '~/utils/common';
import { ucwords } from '~/utils/text';

const uploadMiniModelsPageQuery = graphql(`
  query UploadMiniModelsPage {
    miniModelList {
      ...miniModelParts
      outcrop {
        id
        name
      }
      placement {
        id
      }
    }
  }
`);

async function loader() {
  const res = await apolloClient.query({ query: uploadMiniModelsPageQuery });
  return { miniModels: res.data.miniModelList };
}

type MiniModel = UploadMiniModelsPageQuery['miniModelList'][number];

type ApprovalState = 'draft' | 'ready for approval' | 'approved';

function approvalState(mm: MiniModel): ApprovalState {
  if (mm.readyForApproval) return 'ready for approval';
  if (mm.approved) return 'approved';
  return 'draft';
}

type SortableMiniModel = MiniModel & {
  searchableText: string;
  hasCoordinates: boolean;
  state: ApprovalState;
  aeCount: number;
  isPlaced: boolean;
};

export default function UploadMiniModelsPage() {
  useBreadcrumb('routes/upload/mini-models', 'Manage Mini-Models');
  const loaderData = useLoaderData() as Awaited<ReturnType<typeof loader>>;

  const sortable = loaderData.miniModels.map<SortableMiniModel>(mm => ({
    ...mm,
    hasCoordinates: !!(mm.latitude && mm.longitude),
    state: approvalState(mm),
    aeCount: mm.architecturalElements?.length ?? 0,
    isPlaced: Boolean(mm.placement?.id),
    searchableText: [
      mm.name,
      mm.description,
      mm.architecturalElements?.join(','),
    ]
      .filter(rejectNil)
      .join(' '),
  }));

  const {
    items: miniModels,
    sortIndicatorProps: siProps,
    filterSearchProps,
  } = useSortFilter(sortable, 'name', 'searchableText', 'uploadMiniModels');

  return (
    <>
      <PageHeading>Mini-Models</PageHeading>

      <Panel>
        <Panel.Heading>
          <Panel.Title>
            Mini-Model{' '}
            <span className="text-muted text-sm">
              (<strong>{miniModels.length}</strong> results displayed)
            </span>
          </Panel.Title>
        </Panel.Heading>

        <Panel.Body>
          <FilterSearch
            {...filterSearchProps}
            searchHelpText="Search by Name, Description, or Architectural Elements"
          />

          <table className="table">
            <thead>
              <tr>
                <th>
                  <SortTrigger colName="id" sortIndicatorProps={siProps}>
                    ID
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger colName="name" sortIndicatorProps={siProps}>
                    Name
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger
                    colName="outcrop.name"
                    sortIndicatorProps={siProps}
                    filterable
                  >
                    Outcrop
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger
                    colName="yearCollected"
                    sortIndicatorProps={siProps}
                  >
                    Year
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger
                    colName="collectedBy"
                    sortIndicatorProps={siProps}
                  >
                    Collected By
                  </SortTrigger>
                </th>
                <th className="text-center">
                  <SortTrigger
                    colName="description"
                    sortIndicatorProps={siProps}
                  >
                    Description
                  </SortTrigger>
                </th>
                <th className="text-center">
                  <SortTrigger colName="aeCount" sortIndicatorProps={siProps}>
                    AEs
                  </SortTrigger>
                </th>
                <th className="text-center">
                  <SortTrigger
                    colName="isPlaced"
                    sortIndicatorProps={siProps}
                    filterable
                  >
                    Placed
                  </SortTrigger>
                </th>
                <th className="text-center">
                  <SortTrigger
                    colName="hasCoordinates"
                    sortIndicatorProps={siProps}
                    filterable
                  >
                    Coords
                  </SortTrigger>
                </th>
                <th className="text-center">
                  <SortTrigger
                    colName="state"
                    sortIndicatorProps={siProps}
                    filterable
                    renderFilterOption={ucwords}
                  >
                    State
                  </SortTrigger>
                </th>
              </tr>
            </thead>

            <tbody>
              {miniModels.map(mm => (
                <tr key={mm.id}>
                  <td>{mm.id}</td>
                  <td>
                    <Link
                      to={uploadOutcropUpdateMiniModelsRoute(mm.outcropId)}
                      className="link"
                    >
                      {mm.name}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={uploadOutcropUpdateRoute(mm.outcropId)}
                      className="link"
                    >
                      {mm.outcrop.name}
                    </Link>
                  </td>
                  <td>{mm.yearCollected}</td>
                  <td>{mm.collectedBy}</td>
                  <td>
                    {mm.description?.trim() && (
                      <Tooltip message={mm.description}>
                        <span className="tooltip-indicator">
                          {mm.description?.split(' ').slice(0, 3).join(' ')}
                        </span>
                      </Tooltip>
                    )}
                  </td>
                  <td className="text-center">
                    {mm.architecturalElements?.length ? (
                      <Tooltip
                        message={mm.architecturalElements.join(', ') ?? ''}
                      >
                        <span className="tooltip-indicator">{mm.aeCount}</span>
                      </Tooltip>
                    ) : (
                      0
                    )}
                  </td>
                  <td className="text-center">
                    <EnabledIndicator value={mm.isPlaced} />
                  </td>
                  <td className="text-center">
                    <EnabledIndicator value={mm.hasCoordinates} />
                  </td>
                  <td className="text-center">
                    <Tooltip message={ucwords(approvalState(mm))}>
                      <ApprovalStateText state={approvalState(mm)} />
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Panel.Body>
      </Panel>
    </>
  );
}

UploadMiniModelsPage.loader = loader;

function ApprovalStateText({ state }: { state: ApprovalState }) {
  if (state === 'ready for approval')
    return <FontAwesomeIcon icon={faFlag} className="text-warning" />;
  else if (state === 'approved') return <EnabledIndicator value={true} />;
  else return <FontAwesomeIcon icon={faPencil} className="text-slate-400" />;
}
