import { Field } from 'formik';
import { FormikField } from '~/components/common/FormikField';
import { FormikTinyMceField } from '~/components/common/FormikField/FormikTinyMceField';

export function PageFormFields() {
  return (
    <div className="space-y-2">
      <div className="grid grid-cols-3 gap-6">
        <div className="col-span-2">
          <Field name="title" label="Title" component={FormikField} required />
        </div>
        <div>
          <Field
            name="pageName"
            label="Internal page name"
            component={FormikField}
            required
          />
        </div>
      </div>

      <Field
        name="content"
        label="Content"
        component={FormikField}
        type={FormikTinyMceField}
        config={{ height: 300 }}
        required
      />

      <Field
        name="secured"
        label="Secured"
        component={FormikField}
        type="checkbox"
      />
    </div>
  );
}
