import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from '~/components/ui/button';
import { useNavigate, useSearchParams } from 'react-router';
import { toast } from 'react-toastify';
import type {
  CreatePageMutation,
  CreatePageMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { PageFormFields } from '~/components/upload/page/PageFormFields';
import { adminPageUpdateRoute } from '~/paths';
import type { PageFormValues } from '~/utils/modules/page';
import { defaultPage, pageValidationSchema } from '~/utils/modules/page';
import { ucwords } from '~/utils/text';
import { useUploadPageContext } from '../page';

const CREATE_PAGE = gql`
  mutation createPage($page: PageInput!) {
    createPage(page: $page) {
      id
    }
  }
`;

export default function AdminPageCreate() {
  useBreadcrumb('routes/admin/page/index', 'Create Page');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const slug = searchParams.get('slug') ?? '';
  const { refetchQueries } = useUploadPageContext();

  const [createPage, { loading, error }] = useMutation<
    CreatePageMutation,
    CreatePageMutationVariables
  >(CREATE_PAGE, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  function initialValues() {
    const initialPage = defaultPage();
    if (slug) {
      let title = slug
        .trim()
        .toLowerCase()
        .replaceAll(/[^\w]/g, ' ')
        .replaceAll(/\s+/g, ' ');
      title = ucwords(title);

      initialPage.pageName = slug;
      initialPage.title = title;
    }

    return initialPage;
  }

  async function handleSubmit(values: PageFormValues) {
    try {
      const res = await createPage({ variables: { page: values } });
      const pageId = res.data?.createPage.id;
      if (!pageId) throw new Error('Error parsing response');
      toast.success('Page created successfully.');
      navigate(adminPageUpdateRoute(pageId));
    } catch (err) {
      console.log('Error creating page', err);
      toast.error('There was a problem creating the page.');
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues()}
      validationSchema={pageValidationSchema()}
    >
      <Form>
        <PageFormFields />
        <FormErrors graphQLError={error} />

        <div className="text-center mt-4">
          <Button type="submit" color="primary" disabled={loading}>
            Save
          </Button>
        </div>
      </Form>
    </Formik>
  );
}
