import { useQuery } from '@apollo/client';
import * as R from 'ramda';
import React from 'react';
import { Badge } from '~/components/ui/badge';
import { gql } from '~/apollo/client-v3';
import type {
  OutcropPageNavigationQuery,
  OutcropPageNavigationQueryVariables,
} from '~/apollo/generated/v3/graphql';
import type { NavLinkProps } from '~/components/common/NavLink';
import { NavLink } from '~/components/common/NavLink';
import { Tooltip } from '~/components/common/Tooltip';
import { Menu } from '~/components/ui/menu';
import * as routes from '~/paths';

const OUTCROP_PAGE_NAVIGATION = gql`
  query OutcropPageNavigation($id: Int!) {
    outcropList(id: $id) {
      id
      virtualOutcropModels {
        id
      }
      pictures {
        id
      }
      crossSections {
        id
      }
      facies {
        id
      }
      sedimentaryLogs {
        id
      }
      wellLogs {
        id
      }
      reservoirModels {
        id
      }
      production {
        id
      }
      trainingImages {
        id
      }
      variograms {
        id
      }
      gigaPans {
        id
      }
      fieldPictures {
        id
      }

      studies {
        id
        architecturalElements {
          id
          outcropId
          measurementCount
        }
        pictures {
          id
          outcropTagId
        }
        crossSections {
          id
          outcropTagId
        }
        facies {
          id
          outcropTagId
        }
        sedimentaryLogs {
          id
          outcropTagId
        }
        wellLogs {
          id
          outcropTagId
        }
        reservoirModels {
          id
          outcropTagId
        }
        production {
          id
          outcropTagId
        }
        trainingImages {
          id
          outcropTagId
        }
        variograms {
          id
          outcropTagId
        }
        gigaPans {
          id
          outcropTagId
        }
      }
    }
  }
`;

type CountBadgeProps = {
  children?: React.ReactNode;
  count: number;
};
function CountBadge({ children, count }: CountBadgeProps) {
  return (
    <div>
      <Badge color="ghost" className="break-words">
        {children ? children : count}
      </Badge>
    </div>
  );
}

function OCNavLink(props: NavLinkProps) {
  return (
    <NavLink {...props} className="flex flex-wrap items-start gap-2">
      {props.children}
    </NavLink>
  );
}

type Props = {
  outcropId: number;
};

export function OutcropRouteNavigation({ outcropId }: Props) {
  const { data } = useQuery<
    OutcropPageNavigationQuery,
    OutcropPageNavigationQueryVariables
  >(OUTCROP_PAGE_NAVIGATION, {
    variables: { id: outcropId },
  });

  const outcropList = data?.outcropList ?? [];
  const outcrop = outcropList.find(oc => oc.id === outcropId);

  type QueryOutcrop = OutcropPageNavigationQuery['outcropList'][number];
  type QueryStudy = QueryOutcrop['studies'][number];
  const outcropObjectCount = (property: keyof QueryOutcrop) =>
    R.pipe(
      R.path<any[]>([property]),
      R.defaultTo<any[]>([]),
      R.length,
    )(outcrop);

  const taggedObjectCount = (property: keyof QueryStudy) =>
    R.pipe(
      R.pathOr<QueryStudy[]>([], ['studies']),
      R.reduce((acc, cur) => {
        const count = R.pipe(
          R.path<any[]>([property]),
          R.defaultTo([]),
          R.filter(R.propEq('outcropTagId', outcropId)),
          R.length,
        )(cur);
        return acc + count;
      }, 0),
    )(outcrop);

  function totalObjectCount(property: keyof QueryOutcrop & keyof QueryStudy) {
    const ocCount = outcropObjectCount(property);
    const tagCount = taggedObjectCount(property);
    return ocCount + tagCount;
  }

  const hasOutcropItems = (property: keyof QueryOutcrop) =>
    outcropObjectCount(property) > 0;

  const hasTaggedItems = (property: keyof QueryStudy) =>
    taggedObjectCount(property) > 0;

  // const hasItems = (property: keyof QueryOutcrop & keyof QueryStudy) =>
  //   R.either(hasOutcropItems, hasTaggedItems)(property);
  const hasItems = (ocProp: keyof QueryOutcrop, studyProp: keyof QueryStudy) =>
    hasOutcropItems(ocProp) || hasTaggedItems(studyProp);

  const soFields: Array<keyof QueryOutcrop & keyof QueryStudy> = [
    'pictures',
    'facies',
    'crossSections',
    'sedimentaryLogs',
    'wellLogs',
    'production',
    'reservoirModels',
    'trainingImages',
    'variograms',
    'gigaPans',
  ];
  const hasAnySupportingObjects = R.any(arg => hasItems(arg, arg), soFields);

  const ocStudies = outcrop?.studies ?? [];
  const studyCount = ocStudies.length;
  const ocStudyAEs = ocStudies
    .map(s => s.architecturalElements)
    .reduce(R.concat, [])
    .filter(ae => ae.outcropId === outcropId && ae.measurementCount > 0);
  const measurementCount = ocStudyAEs.reduce(
    (acc, cur) => acc + cur.measurementCount,
    0,
  );
  const aeCount = ocStudyAEs.length;

  const hasAnyMeasurements = measurementCount > 0;

  const numFieldPics = outcropObjectCount('fieldPictures');

  return (
    <>
      <Menu>
        <Menu.Item>
          <OCNavLink to={routes.outcropOverviewTabRoute(outcropId)} end>
            Overview
          </OCNavLink>
        </Menu.Item>
        <Menu.Item>
          <OCNavLink to={routes.outcropKeyParametersTabRoute(outcropId)}>
            Key Parameters
          </OCNavLink>
        </Menu.Item>
        {hasOutcropItems('virtualOutcropModels') && (
          <Menu.Item>
            <OCNavLink to={routes.outcropVirtualOutcropsTabRoute(outcropId)}>
              Virtual Outcrop Models
              <CountBadge count={outcropObjectCount('virtualOutcropModels')} />
            </OCNavLink>
          </Menu.Item>
        )}
        <Menu.Item>
          <OCNavLink to={routes.outcropAnaloguesTabRoute(outcropId)}>
            Analogues
          </OCNavLink>
        </Menu.Item>
        <Menu.Item>
          <OCNavLink to={routes.outcropPalaeogeographyTabRoute(outcropId)}>
            Palaeogeography
          </OCNavLink>
        </Menu.Item>
        {hasOutcropItems('studies') && (
          <Menu.Item>
            <OCNavLink to={routes.outcropStudiesTabRoute(outcropId)}>
              Studies <CountBadge count={studyCount} />
            </OCNavLink>
          </Menu.Item>
        )}

        {hasAnyMeasurements && (
          <>
            <li />
            <Menu.Item>
              <OCNavLink to={routes.outcropMeasurementsTabRoute(outcropId)}>
                Measurements{' '}
                <Tooltip
                  message={`${measurementCount} measurements on ${aeCount} architectural elements`}
                >
                  <span>
                    <CountBadge count={measurementCount}>
                      {measurementCount} / {aeCount} AEs
                    </CountBadge>
                  </span>
                </Tooltip>
              </OCNavLink>
            </Menu.Item>
          </>
        )}

        {hasAnySupportingObjects && <li />}

        {hasItems('pictures', 'pictures') && (
          <Menu.Item>
            <OCNavLink to={routes.outcropPicturesTabRoute(outcropId)}>
              Pictures <CountBadge count={totalObjectCount('pictures')} />
            </OCNavLink>
          </Menu.Item>
        )}
        {hasItems('facies', 'facies') && (
          <Menu.Item>
            <OCNavLink to={routes.outcropFaciesTabRoute(outcropId)}>
              Facies <CountBadge count={totalObjectCount('facies')} />
            </OCNavLink>
          </Menu.Item>
        )}
        {hasItems('crossSections', 'crossSections') && (
          <Menu.Item>
            <OCNavLink to={routes.outcropCrossSectionsTabRoute(outcropId)}>
              Cross Sections
              <CountBadge count={totalObjectCount('crossSections')} />
            </OCNavLink>
          </Menu.Item>
        )}
        {hasItems('sedimentaryLogs', 'sedimentaryLogs') && (
          <Menu.Item>
            <OCNavLink to={routes.outcropSedimentaryLogsTabRoute(outcropId)}>
              Sedimentary Logs
              <CountBadge count={totalObjectCount('sedimentaryLogs')} />
            </OCNavLink>
          </Menu.Item>
        )}
        {hasItems('wellLogs', 'wellLogs') && (
          <Menu.Item>
            <OCNavLink to={routes.outcropWellLogsTabRoute(outcropId)}>
              Well Logs <CountBadge count={totalObjectCount('wellLogs')} />
            </OCNavLink>
          </Menu.Item>
        )}
        {hasItems('production', 'production') && (
          <Menu.Item>
            <OCNavLink to={routes.outcropProductionTabRoute(outcropId)}>
              Production <CountBadge count={totalObjectCount('production')} />
            </OCNavLink>
          </Menu.Item>
        )}
        {hasItems('reservoirModels', 'reservoirModels') && (
          <Menu.Item>
            <OCNavLink to={routes.outcropReservoirModelsTabRoute(outcropId)}>
              Reservoir Models
              <CountBadge count={totalObjectCount('reservoirModels')} />
            </OCNavLink>
          </Menu.Item>
        )}
        {hasItems('trainingImages', 'trainingImages') && (
          <Menu.Item>
            <OCNavLink to={routes.outcropTrainingImagesTabRoute(outcropId)}>
              Training Images
              <CountBadge count={totalObjectCount('trainingImages')} />
            </OCNavLink>
          </Menu.Item>
        )}
        {hasItems('variograms', 'variograms') && (
          <Menu.Item>
            <OCNavLink to={routes.outcropVariogramsTabRoute(outcropId)}>
              Variograms <CountBadge count={totalObjectCount('variograms')} />
            </OCNavLink>
          </Menu.Item>
        )}
        {hasItems('gigaPans', 'gigaPans') && (
          <Menu.Item>
            <OCNavLink to={routes.outcropPanoramasTabRoute(outcropId)}>
              Panoramas <CountBadge count={totalObjectCount('gigaPans')} />
            </OCNavLink>
          </Menu.Item>
        )}
        {numFieldPics > 0 && (
          <Menu.Item>
            <OCNavLink to={routes.outcropFieldPicturesTabRoute(outcropId)}>
              Field Pictures <CountBadge count={numFieldPics} />
            </OCNavLink>
          </Menu.Item>
        )}
      </Menu>
    </>
  );
}
