import { useQuery } from '@apollo/client';
import {
  faExternalLink,
  faPencil,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router';
import { gql } from '~/apollo/client-v3';
import type { UploadRegionListPageQuery } from '~/apollo/generated/v3/graphql';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import { FilterSearch } from '~/components/common/FilterSearch';
import { NoItemsRow } from '~/components/common/NoItemsRow';
import { Panel } from '~/components/common/Panel';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useSortFilter } from '~/hooks/data';
import {
  regionRoute,
  uploadRegionCreateRoute,
  uploadRegionUpdateOutcropsRoute,
  uploadRegionUpdateRoute,
} from '~/paths';

const REGION_LIST_PAGE = gql`
  query UploadRegionListPage {
    regionList {
      id
      name
      location {
        id
        location
        country
      }
      outcrops {
        id
      }
    }
  }
`;

export default function UploadRegionListPage() {
  const { data, loading } =
    useQuery<UploadRegionListPageQuery>(REGION_LIST_PAGE);

  const regions =
    data?.regionList.map(r => ({
      ...r,
      outcropCount: r.outcrops.length,
    })) ?? [];

  const {
    items,
    sortIndicatorProps: siProps,
    filterSearchProps,
  } = useSortFilter(regions, 'name', 'name', 'uploadRegionList');

  if (loading) return <SpinnerPlaceholder />;

  return (
    <Panel>
      <Panel.Heading className="flex justify-between">
        <Panel.Title>
          Regions
          <small className="text-muted ml-2">
            (<strong>{items.length}</strong> results displayed)
          </small>
        </Panel.Title>

        <Link
          to={uploadRegionCreateRoute()}
          className="btn btn-primary btn-xs gap-1"
        >
          <FontAwesomeIcon icon={faPlusCircle} /> Create Region
        </Link>
      </Panel.Heading>
      <Panel.Body>
        <FilterSearch {...filterSearchProps} />

        <table className="table table-compact w-full">
          <thead>
            <tr>
              <th>
                <SortTrigger colName="id" sortIndicatorProps={siProps}>
                  ID
                </SortTrigger>
              </th>
              <th>
                <SortTrigger colName="name" sortIndicatorProps={siProps}>
                  Name
                </SortTrigger>
              </th>
              <th>
                <SortTrigger
                  colName="location.location"
                  sortIndicatorProps={siProps}
                  filterable
                >
                  Location
                </SortTrigger>
              </th>
              <th>
                <SortTrigger
                  colName="location.country"
                  sortIndicatorProps={siProps}
                  filterable
                >
                  Country
                </SortTrigger>
              </th>
              <th className="text-center">
                <SortTrigger
                  colName="outcropCount"
                  sortIndicatorProps={siProps}
                >
                  Outcrops
                </SortTrigger>
              </th>
              <th />
            </tr>
          </thead>

          <tbody>
            <NoItemsRow show={!items.length} colSpan={6} />

            {items.map(region => (
              <tr key={region.id}>
                <td>{region.id}</td>
                <td>
                  <Link
                    to={uploadRegionUpdateRoute(region.id)}
                    className="link"
                  >
                    {region.name}
                  </Link>
                </td>
                <td>{region.location.location}</td>
                <td>{region.location.country}</td>
                <td className="text-center">
                  <Link
                    to={uploadRegionUpdateOutcropsRoute(region.id)}
                    className="link"
                  >
                    {region.outcropCount}
                  </Link>
                </td>
                <td className="text-right">
                  <div className="join">
                    <Link
                      to={uploadRegionUpdateRoute(region.id)}
                      className="btn btn-sm btn-ghost gap-1 join-item"
                    >
                      <FontAwesomeIcon icon={faPencil} /> Edit
                    </Link>

                    <DropdownMenu color="ghost" size="sm" join>
                      <DropdownMenu.Items>
                        <DropdownMenu.Item>
                          <Link to={regionRoute(region.id)} target="_blank">
                            View <FontAwesomeIcon icon={faExternalLink} />
                          </Link>
                        </DropdownMenu.Item>
                      </DropdownMenu.Items>
                    </DropdownMenu>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Panel.Body>
    </Panel>
  );
}
