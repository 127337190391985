import { useQuery } from '@apollo/client';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Papa from 'papaparse';
import { Button } from '~/components/ui/button';
import { Link } from 'react-router';
import { graphql } from '~/apollo/generated/v3';
import type { ExportOutcropsPageQuery } from '~/apollo/generated/v3/graphql';
import { PageHeading } from '~/components/common/PageHeading';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { useSortFilter } from '~/hooks/data';
import { uploadOutcropUpdateRoute } from '~/paths';
import { saveAsCSV } from '~/utils/export';
import { toISODateString } from '~/utils/text';

const EXPORT_OUTCROPS_PAGE = graphql(`
  query ExportOutcropsPage {
    outcropList {
      ...outcropParts
      insertedAt
      updatedAt
      thumbnail {
        id
      }
      region {
        name
        location {
          country
        }
      }
      basin {
        name
        description
        basinType
        climate
        terrestrialMarine
        waterDepth
        catchmentArea
      }
      sourceArea {
        name
        climate
        description
        geography
        structure
        distanceToSourceArea
        distanceToShoreline
        distanceToShelfEdge
      }
      qualityParameters {
        ...outcropQualityParametersParts
      }
      keyParameters {
        ...keyParametersParts
      }
      startAge {
        ...lithostratAgeParts
      }
      georeferences {
        id
        dataType
      }
      virtualOutcropModels {
        id
      }
      studies {
        id
      }
      paleoMaps {
        id
      }
      pictures {
        id
      }
      crossSections {
        id
      }
      facies {
        id
      }
      sedimentaryLogs {
        id
      }
      wellLogs {
        id
      }
      reservoirModels {
        id
      }
      production {
        id
      }
      trainingImages {
        id
      }
      variograms {
        id
      }
      gigaPans {
        id
      }
      lithostratOutcropLinks {
        id
      }
      center {
        lat
        lng
      }
      outline {
        id
      }
      miniModels {
        id
      }
      photo360s {
        id
      }
      videos {
        id
      }
    }
  }
`);

function formatDate(date?: Date | string | null | undefined) {
  if (!date) return null;
  return moment(date).format('YYYY-MM-DD hh:mm:ss');
}

type OutcropResult = ExportOutcropsPageQuery['outcropList'][number];

const vomCount = (outcrop: OutcropResult) =>
  (outcrop.virtualOutcropModels ?? []).length;

const hasPaleoMaps = (outcrop: OutcropResult) => outcrop.paleoMaps.length > 0;

const kps = (outcrop: OutcropResult) => outcrop.keyParameters;

const hasTextValue = (value?: string | null) => !!value && value.length > 0;

const boolToText = (val: boolean) => (val ? 'yes' : 'no');

type Row = {
  ID: number;
  Name: string;
  Region: string;
  Country: string;
  'Outcrop Type': string;
  'Geology Type': string;
  'Analogue Category': string;
  'Description Text': string; // (yes/no)
  'Lithology and Depositional Environment': string; // (yes/no)
  'Litho- and Sequence Stratigraphy': string; // (yes/no)
  'Analogues and Notable Aspects': string; // (yes/no)
  'Location and Accessibility': string; // (yes/no)
  'Key References': string; // (yes/no)
  Introduction: string; // (yes/no)
  'Key Words': string; // (yes/no)
  'Diagenesis and Petrophysical Properties': string; // (yes/no)
  'Structural Geology': string; // (yes/no)
  'Ready to Approve': string; // (yes/no)
  Approved: string; // (yes/no)
  'QC Completed': string; // (yes/no)
  'Basin Name'?: string;
  'Basin Description'?: string; // (yes/no)
  'Basin Type'?: string;
  Climate?: string;
  'Terrestrial/Marine'?: string;
  'Water Depth'?: string;
  'Catchment Area'?: number | null;
  'Source Area Name'?: string | null;
  'Source Area Description'?: string; // (yes/no)
  Geography?: string | null;
  Structure?: string | null;
  'Source Area Climate'?: string | null;
  'Distance to SA'?: number | null;
  'Distance to Shoreline'?: number | null;
  'Disctane to Shelf Edge'?: number | null;
  'Depositional Dip Direction'?: string | null;
  'Outcrop Orientation': string;
  'Outcrop 3D control'?: string;
  'Exposure Quality'?: string;
  'Structural Complexity'?: string;
  'Dataset Scale'?: string;
  GDE: string;
  DE: string;
  SE: string;
  AE: string; // (yes/no)
  'Virtual Outcrops': number;
  Palaeogeography: string; // (yes/no)
  Georeferences: string; // (yes/no)
  'Geological Age'?: string; // (system value)
  Studies: number; // (count)
  Lithostratigraphy: string; // (yes/no)
  Thumbnail: string; // (yes/no)
  Pictures: number; // (count)
  Facies: number; // (count)
  'Cross Sections': number; // (count)
  'Sedimentary Logs': number; // (count)
  'Well logs': number; // (count)
  Production: number; // (count)
  'Reservoir Models': number; // (count)
  'Training Images': number; // (count)
  Variograms: number; // (count)
  Panoramas: number; // (count)
  'Mini-Models': number; // (count)
  '360 Photos': number; // (count)
  Videos: number; // (count)
  'Has Center': string; // (yes/no)
  'Has Outline': string; // (yes/no)
  'Dominant geology type': string;
  'Systems tract': string;
  'Shoreline tractory': string;
  'Dune shape': string;
  'Channel morphology': string;
  'River profile location': string;
  'Dominant lithology': string;
  'Water temperature': string;
  'Net to gross': string;
  'Diagenetic setting': string;
  'Diagenetic process': string;
  'Diagenetic geometry': string;
  'Tectonic setting': string;
  'Syn sedimentary deformation': string;
  'Fault rocks membranes': string;
  'Interaction network': string;
  'Reactivation1st phase': string;
  'Reactivation2nd phase': string;
  'Symmetry geometry': string;
  'Multiple folds': string;
  'Secondary structures': string;
  'Lateral aggregation': string;
  'Inserted At': string;
  'Updated At': string;
};

function toRow(oc: OutcropResult): Row {
  return {
    ID: oc.id,
    Name: oc.name,
    Region: oc.region.name ?? '',
    Country: oc.region?.location.country ?? '',
    'Outcrop Type': oc.outcropType ?? '',
    'Geology Type': oc.geologyType.join(', '),
    'Analogue Category': oc.outcropCategory ?? '',
    'Description Text': boolToText(hasTextValue(oc.description)),
    'Lithology and Depositional Environment': boolToText(
      hasTextValue(oc.environments),
    ),
    'Litho- and Sequence Stratigraphy': boolToText(
      hasTextValue(oc.stratigraphy),
    ),
    'Analogues and Notable Aspects': boolToText(
      hasTextValue(oc.notablesAndAnalogues),
    ),
    'Location and Accessibility': boolToText(hasTextValue(oc.location)),
    'Key References': boolToText(hasTextValue(oc.safari)),
    Introduction: boolToText(hasTextValue(oc.introduction)),
    'Key Words': boolToText(hasTextValue(oc.keyWords)),
    'Diagenesis and Petrophysical Properties': boolToText(
      hasTextValue(oc.diagenesisAndPetrophysicalProperties),
    ),
    'Structural Geology': boolToText(hasTextValue(oc.structuralGeology)),
    'Ready to Approve': boolToText(oc.readyForApproval),
    Approved: boolToText(oc.approved),
    'QC Completed': boolToText(oc.qcCompleted),
    'Basin Name': oc.basin?.name ?? '',
    'Basin Description': boolToText(hasTextValue(oc.basin?.description)),
    'Basin Type': oc.basin?.basinType ?? '',
    Climate: oc.basin?.climate ?? '',
    'Terrestrial/Marine': oc.basin?.terrestrialMarine ?? '',
    'Water Depth': oc.basin?.waterDepth ?? '',
    'Catchment Area': oc.basin?.catchmentArea,
    'Source Area Name': oc.sourceArea?.name,
    'Source Area Description': boolToText(
      hasTextValue(oc.sourceArea?.description),
    ),
    Geography: oc.sourceArea?.geography,
    Structure: oc.sourceArea?.structure,
    'Source Area Climate': oc.sourceArea?.climate,
    'Distance to SA': oc.sourceArea?.distanceToSourceArea,
    'Distance to Shoreline': oc.sourceArea?.distanceToShoreline,
    'Disctane to Shelf Edge': oc.sourceArea?.distanceToShelfEdge,
    'Depositional Dip Direction': oc.depositionalDipDirection,
    'Outcrop Orientation': oc.orientation ?? '',
    'Outcrop 3D control': oc.qualityParameters?.outcrop_3d_control ?? '',
    'Exposure Quality': oc.qualityParameters?.exposureQuality ?? '',
    'Structural Complexity': oc.qualityParameters?.structuralComplexity ?? '',
    'Dataset Scale': oc.qualityParameters?.datasetScale ?? '',
    GDE: kps(oc)
      .map(kp => kp.grossDepositionalEnvironment)
      .join(', '),
    DE: kps(oc)
      .map(kp => kp.depositionalEnvironment)
      .join(', '),
    SE: kps(oc)
      .map(kp => kp.depositionalSubEnvironment)
      .join(', '),
    AE: boolToText(kps(oc).map(kp => kp.architecturalElement).length > 0),
    'Virtual Outcrops': vomCount(oc),
    Palaeogeography: boolToText(hasPaleoMaps(oc)),
    Georeferences: boolToText(
      !!oc.georeferences && oc.georeferences.length > 0,
    ),
    'Geological Age': oc.startAge?.system ?? '',
    Studies: oc.studies?.length || 0,
    Lithostratigraphy: boolToText(
      !!oc.lithostratOutcropLinks && oc.lithostratOutcropLinks.length > 0,
    ),
    Thumbnail: boolToText(!!oc.thumbnail?.id),
    Pictures: oc.pictures?.length || 0,
    Facies: oc.facies?.length || 0,
    'Cross Sections': oc.crossSections?.length || 0,
    'Sedimentary Logs': oc.sedimentaryLogs?.length || 0,
    'Well logs': oc.wellLogs?.length || 0,
    Production: oc.production?.length || 0,
    'Reservoir Models': oc.reservoirModels?.length || 0,
    'Training Images': oc.trainingImages?.length || 0,
    Variograms: oc.variograms?.length || 0,
    Panoramas: oc.gigaPans?.length || 0,
    'Mini-Models': oc.miniModels?.length || 0,
    '360 Photos': oc.photo360s?.length || 0,
    Videos: oc.videos?.length || 0,
    'Has Center': boolToText(oc.center !== null),
    'Has Outline': boolToText(!!oc.outline),

    // Additional fields
    'Dominant geology type': oc.dominantGeologyType ?? '',
    'Systems tract': oc.systemsTract?.join(', ') ?? '',
    'Shoreline tractory': oc.shorelineTractory?.join(', ') ?? '',
    'Dune shape': oc.duneShape?.join(', ') ?? '',
    'Channel morphology': oc.channelMorphology?.join(', ') ?? '',
    'River profile location': oc.riverProfileLocation?.join(', ') ?? '',
    'Dominant lithology': oc.dominantLithology?.join(', ') ?? '',
    'Water temperature': oc.waterTemperature?.join(', ') ?? '',
    'Net to gross': oc.netToGross ?? '',
    'Diagenetic setting': oc.diageneticSetting?.join(', ') ?? '',
    'Diagenetic process': oc.diageneticProcess?.join(', ') ?? '',
    'Diagenetic geometry': oc.diageneticGeometry?.join(', ') ?? '',
    'Tectonic setting': oc.tectonicSetting?.join(', ') ?? '',
    'Syn sedimentary deformation':
      oc.synSedimentaryDeformation?.join(', ') ?? '',
    'Fault rocks membranes': oc.faultRocksMembranes?.join(', ') ?? '',
    'Interaction network': oc.interactionNetwork?.join(', ') ?? '',
    'Reactivation1st phase': oc.reactivation1stPhase?.join(', ') ?? '',
    'Reactivation2nd phase': oc.reactivation2ndPhase?.join(', ') ?? '',
    'Symmetry geometry': oc.symmetryGeometry?.join(', ') ?? '',
    'Multiple folds': oc.multipleFolds?.join(', ') ?? '',
    'Secondary structures': oc.secondaryStructures?.join(', ') ?? '',
    'Lateral aggregation': oc.lateralAggregation?.join(', ') ?? '',
    'Inserted At': formatDate(oc.insertedAt) ?? '',
    'Updated At': formatDate(oc.updatedAt) ?? '',
  };
}

export default function ExportOutcropsRoute() {
  useBreadcrumb('routes/upload/export/outcrops', 'Outcrop Master Sheet');

  const { data, loading } = useQuery(EXPORT_OUTCROPS_PAGE);

  const rows = data?.outcropList.map(toRow) ?? [];

  const fields = Object.keys(rows.at(0) ?? {}) as Array<keyof Row>;

  const { items, sortIndicatorProps: siProps } = useSortFilter(
    rows,
    'ID',
    'Name',
    'exportOutcropsList',
  );

  function handleSave() {
    const data = Papa.unparse(rows);
    const date = toISODateString(new Date());
    saveAsCSV(data, `Outcrops Export ${date}.csv`);
  }

  if (loading) return <SpinnerPlaceholder />;

  return (
    <>
      <div className="container-fluid">
        <div className="float-right">
          <Button
            type="button"
            variant="link"
            onClick={handleSave}
            className="gap-1"
          >
            <FontAwesomeIcon icon={faFileCsv} /> Save as CSV
          </Button>
        </div>

        <PageHeading>Outcrop Master Sheet</PageHeading>
        <p>Master list of all outcrops</p>
      </div>

      <div className="">
        <table className="table table-compact text-sm">
          <thead className="sticky top-0 bg-sky-100">
            <tr>
              {fields.map(field => (
                <th key={field}>
                  <SortTrigger colName={field} sortIndicatorProps={siProps}>
                    <span style={{ fontSize: '9pt' }}>{field}</span>
                  </SortTrigger>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {items.map(item => (
              <tr key={item.ID}>
                {fields.slice(0, 1).map(fieldName => (
                  <td key={fieldName} className="sticky left-0 bg-sky-100">
                    <Link
                      to={uploadOutcropUpdateRoute(item.ID)}
                      target="_blank"
                      className="link"
                    >
                      {item[fieldName]}
                    </Link>
                  </td>
                ))}
                {fields.slice(1).map(fieldName => (
                  <td key={fieldName} className="border border-slate-100">
                    {item[fieldName]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
