import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v3';
import type { PagePartsFragment } from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { Panel } from '~/components/common/Panel';
import type { PageFormValues } from '~/utils/modules/page';
import { defaultPage, pageValidationSchema } from '~/utils/modules/page';
import { PageFormFields } from './PageFormFields';

const UPDATE_PAGE = graphql(`
  mutation UpdatePage($id: Int!, $page: PageInput!) {
    updatePage(id: $id, page: $page) {
      ...pageParts
    }
  }
`);

type Props = {
  page: PagePartsFragment;
};

const UpdatePageForm: React.FC<Props> = ({ page }: Props) => {
  const [updatePage, { loading, error }] = useMutation(UPDATE_PAGE);

  async function handleSubmit(values: PageFormValues) {
    try {
      await updatePage({ variables: { id: page.id, page: values } });
      toast.success('Page updated successfully.');
    } catch (err) {
      console.log('Error updating page', err);
      toast.error('There was a problem updating the page.');
    }
  }

  return (
    <Formik
      key={page.id}
      onSubmit={handleSubmit}
      initialValues={defaultPage(page)}
      validationSchema={pageValidationSchema}
    >
      <Form>
        <Panel>
          <Panel.Body>
            <div className="space-y-4">
              <PageFormFields />
              <FormErrors graphQLError={error} />
            </div>
          </Panel.Body>
          <Panel.Footer>
            <div className="text-right">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                Save
              </button>
            </div>
          </Panel.Footer>
        </Panel>
      </Form>
    </Formik>
  );
};

export default UpdatePageForm;
