import qs from 'qs';
import { useCallback } from 'react';
import { useLocation, useParams } from 'react-router';
import invariant from 'tiny-invariant';

export function useQueryString<T>() {
  const location = useLocation();

  // Strip leading ? in search string if exists
  const queryString = location.search.replace(/^\?/, '');

  const query = qs.parse(queryString) as unknown as T;

  const stringify = useCallback((obj: any) => qs.stringify(obj), []);

  return { queryString, query, stringify };
}

type RouteParamParser<T> = (value: string) => T;

export function useRouteParam(param: string, parser: undefined): string;
export function useRouteParam<T>(param: string, parser: RouteParamParser<T>): T;
export function useRouteParam<T>(param: string, parser?: RouteParamParser<T>) {
  const params = useParams();
  const value = params[param];
  invariant(value, `Route param ${param} is required`);

  if (!parser) return value;
  return parser(value);
}
