import type { PureQueryOptions } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/client';
import {
  faExternalLink,
  faPenToSquare,
  faPlus,
  faVideoCamera,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Entity, ScreenSpaceEventHandler, Viewer } from 'cesium';
import {
  Cartesian3,
  Cartographic,
  ConstantPositionProperty,
  ConstantProperty,
  HeadingPitchRoll,
  JulianDate,
} from 'cesium';
import * as R from 'ramda';
import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { JoystickShape } from 'react-joystick-component';
import type { LinkProps } from 'react-router';
import { Link, useParams } from 'react-router';
import invariant from 'tiny-invariant';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import type {
  CesiumLocationPartsFragment,
  CreateSupportingObjectPlacementMutationVariables,
  CrossSection,
  FaciesSchema,
  FieldPicture,
  GigaPan,
  MiniModel,
  OutcropSoPlacementsPageQuery,
  OutcropSoPlacementsPageQueryVariables,
  Photo360,
  Picture,
  Production,
  ReservoirModel,
  SedimentaryLog,
  TrainingImage,
  UpdateSupportingObjectDefaultCameraMutationVariables,
  UpdateSupportingObjectPlacementLocationMutationVariables,
  UpdateSupportingObjectPlacementMutationVariables,
  Variogram,
  Video,
  WellLog,
} from '~/apollo/generated/v4/graphql';
import { CesiumPlacementType } from '~/apollo/generated/v4/graphql';
import {
  addIconPoint,
  addInfoBoxIconPoint,
  convertCartesian3Tollhhpr,
  convertFromCartesian,
  enableEntityClickPlacement,
  enableEntityInfoBox,
  getCameraCartographic,
  getCurrentZoomDistance,
  setBillboardSize,
  TerrainProvider,
  toggleEntityVisibility,
  type TilesetParams,
} from '~/components/cesium/cesiumUtils';
import {
  CesiumViewer,
  type LatLngHeightHPR,
} from '~/components/cesium/CesiumViewer';
import SignalJoystick from '~/components/cesium/Joystick';
import type { IJoystickUpdateEvent } from '~/components/cesium/placementFields';
import type { iconFileNames } from '~/components/common/CesiumIconSelector';
import { IconSelector } from '~/components/common/CesiumIconSelector';
import { Heading } from '~/components/common/Heading';
import { ExpandedIcon } from '~/components/common/icons/ExpandedIcon';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerIcon } from '~/components/common/SpinnerIcon';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { useFullWidthContext } from '~/components/layout/fullWidthContext';
import { Button } from '~/components/ui/button';
import { SOPlacementStatusModal } from '~/components/upload/supportingObject/placement/SOPlacementStatusModal';
import { studyRoute, uploadOutcropUpdateRoute } from '~/paths';
import { cn } from '~/utils/common';

const outcropSOPlacementsPageQuery = graphql(`
  query OutcropSOPlacementsPage($id: ID!) {
    outcropGet(id: $id) {
      id
      name
      virtualOutcropModels {
        id
        name
        cesiumAsset {
          ...cesiumAssetParts
          tilesetToken {
            token
          }
          location {
            ...cesiumLocationParts
          }
          defaultCamera {
            ...cesiumLocationParts
          }
        }
      }
      pictures {
        ...placementPictureParts
        placement {
          ...placementSOPlacementParts
        }
      }
      facies {
        id
        name
        pictures {
          ...placementPictureParts
        }
        placement {
          ...placementSOPlacementParts
        }
      }
      crossSections {
        id
        name
        georeference {
          ...placementGeorefParts
        }
        pictures {
          ...placementPictureParts
        }
        placement {
          ...placementSOPlacementParts
        }
      }
      sedimentaryLogs {
        id
        name
        georeference {
          ...placementGeorefParts
        }
        pictures {
          ...placementPictureParts
        }
        placement {
          ...placementSOPlacementParts
        }
      }
      wellLogs {
        id
        name
        pictures {
          ...placementPictureParts
        }
        placement {
          ...placementSOPlacementParts
        }
      }
      production {
        id
        name
        pictures {
          ...placementPictureParts
        }
        placement {
          ...placementSOPlacementParts
        }
      }
      reservoirModels {
        id
        name
        pictures {
          ...placementPictureParts
        }
        placement {
          ...placementSOPlacementParts
        }
      }
      trainingImages {
        id
        name
        pictures {
          ...placementPictureParts
        }
        placement {
          ...placementSOPlacementParts
        }
      }
      variograms {
        id
        name
        pictures {
          ...placementPictureParts
        }
        placement {
          ...placementSOPlacementParts
        }
      }
      gigaPans {
        id
        name
        georeference {
          ...placementGeorefParts
        }
        pictures {
          ...placementPictureParts
        }
        placement {
          ...placementSOPlacementParts
        }
      }
      fieldPictures {
        id
        file {
          ...fileParts
        }
        placement {
          ...placementSOPlacementParts
        }
      }
      miniModels {
        id
        name
        url
        placement {
          ...placementSOPlacementParts
        }
      }
      photo360s {
        id
        name
        url
        placement {
          ...placementSOPlacementParts
        }
      }
      videos {
        id
        name
        url
        placement {
          ...placementSOPlacementParts
        }
      }

      studies {
        id
        name
        dataHistory {
          id
          collectedBy
          date
        }
        pictures {
          ...placementPictureParts
          placement {
            ...placementSOPlacementParts
          }
        }
        facies {
          id
          name
          outcropTagId
          pictures {
            ...placementPictureParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
        crossSections {
          id
          name
          outcropTagId
          georeference {
            ...placementGeorefParts
          }
          pictures {
            ...placementPictureParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
        sedimentaryLogs {
          id
          name
          outcropTagId
          georeference {
            ...placementGeorefParts
          }
          pictures {
            ...placementPictureParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
        wellLogs {
          id
          name
          outcropTagId
          pictures {
            ...placementPictureParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
        production {
          id
          name
          outcropTagId
          pictures {
            ...placementPictureParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
        reservoirModels {
          id
          name
          outcropTagId
          pictures {
            ...placementPictureParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
        trainingImages {
          id
          name
          outcropTagId
          pictures {
            ...placementPictureParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
        variograms {
          id
          name
          outcropTagId
          pictures {
            ...placementPictureParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
        gigaPans {
          id
          name
          outcropTagId
          georeference {
            ...placementGeorefParts
          }
          pictures {
            ...placementPictureParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
      }
    }
  }
`);

const createSOPlacementMutation = graphql(`
  mutation CreateSupportingObjectPlacement(
    $input: SupportingObjectPlacementCreateInput!
  ) {
    supportingObjectPlacementCreate(input: $input) {
      result {
        ...placementSOPlacementParts
      }
    }
  }
`);

const updateSOPlacementMutation = graphql(`
  mutation UpdateSupportingObjectPlacement(
    $id: ID!
    $input: SupportingObjectPlacementUpdateInput!
  ) {
    supportingObjectPlacementUpdate(id: $id, input: $input) {
      result {
        ...placementSOPlacementParts
      }
    }
  }
`);

const updateSOPlacementLocationMutation = graphql(`
  mutation UpdateSupportingObjectPlacementLocation(
    $id: ID!
    $input: SupportingObjectPlacementSaveLocationInput!
  ) {
    supportingObjectPlacementSaveLocation(id: $id, input: $input) {
      result {
        ...placementSOPlacementParts
      }
    }
  }
`);

const updateSOPlacementDefaultCameraMutation = graphql(`
  mutation UpdateSupportingObjectDefaultCamera(
    $id: ID!
    $input: SupportingObjectPlacementSaveDefaultCameraInput!
  ) {
    supportingObjectPlacementSaveDefaultCamera(id: $id, input: $input) {
      result {
        ...placementSOPlacementParts
      }
    }
  }
`);

type Outcrop = NonNullable<OutcropSoPlacementsPageQuery['outcropGet']>;

type Vom = Outcrop['virtualOutcropModels'][number];

type Study = Outcrop['studies'][number];

type Pictures =
  Outcrop['studies'][number]['crossSections'][number]['pictures'][number];

type Georeference =
  Outcrop['studies'][number]['crossSections'][number]['georeference'];

type Placement =
  Outcrop['studies'][number]['crossSections'][number]['placement'];

type PlacementOutcrop = Outcrop['pictures'][number]['placement'];

type File = Outcrop['studies'][number]['pictures'][number]['file'];

export type SO = {
  __typename?:
    | Picture['__typename']
    | FaciesSchema['__typename']
    | CrossSection['__typename']
    | SedimentaryLog['__typename']
    | WellLog['__typename']
    | Production['__typename']
    | ReservoirModel['__typename']
    | TrainingImage['__typename']
    | Variogram['__typename']
    | GigaPan['__typename']
    | FieldPicture['__typename']
    | MiniModel['__typename']
    | Photo360['__typename']
    | Video['__typename'];
  id: string;
  name: string;
  file?: File;
  pictures?: Pictures[];
  outcropTagId?: number | null | undefined;
  georeference?: Georeference;
  placement?: Placement | PlacementOutcrop;
  url?: string;
};

type IconFileNames = (typeof iconFileNames)[number];

const iconDefaults: Record<string, IconFileNames> = {
  Picture: 'Photo',
  FaciesSchema: 'Depositional Setting',
  CrossSection: 'XSection',
  SedimentaryLog: 'SediLog',
  WellLog: 'WellLog',
  Production: 'Doc',
  ReservoirModel: 'Depositional Setting',
  TrainingImage: 'Photo',
  Variogram: 'Map',
  GigaPan: 'Weblink',
  FieldPicture: 'Photo',
  MiniModel: 'MiniModel',
  Photo360: '360',
  Video: 'Video',
  Marker: 'Marker',
};

type PlacementPageContextValue = {
  outcrop: Outcrop;
  setViewer: (viewer: Viewer) => void;
  visibleTilesets: TilesetParams[];
  toggleTilesetVisibility: (assetToken: string) => void;
  toggleSOVisibility: (soId: string) => boolean;
  showPlacementMode: (value: boolean, item: SO | null) => void;
  placementMode: boolean;
  currentPlacement: LatLngHeightHPR | null;
  setCurrentPlacement: (placement: LatLngHeightHPR) => void;
  selectedSO: SO | null;
  viewer: Viewer | null;
  setEventHandler: (eh: ScreenSpaceEventHandler | null) => void;
  eventHandler: ScreenSpaceEventHandler | null;
  setCurrentEntity: (entity: Entity | null) => void;
  currentEntity: Entity | null;
  currentEntityType: CesiumPlacementType;
  setCurrentEntityType: (type: CesiumPlacementType) => void;
  flyToLocation: (dc: CesiumLocationPartsFragment) => void;
  imageSize: { width: number; height: number };
  setImageSize: ({ width, height }: { width: number; height: number }) => void;
  imageSrc: string | undefined;
  setImageSrc: (src: string | undefined) => void;
  cameraZoomLevel: number;
  setCameraZoomLevel: (l: number) => void;
  currentIcon: string | undefined;
  setCurrentIcon: (icon: string | undefined) => void;
};

const PlacementPageContext = createContext<PlacementPageContextValue | null>(
  null,
);

export function isIframeType(so: SO): boolean {
  if (
    so.__typename &&
    ['MiniModel', 'Photo360', 'Video'].includes(so.__typename)
  ) {
    return true;
  }
  return false;
}

export function initialTilesets(outcrop: Outcrop): TilesetParams[] {
  return outcrop.virtualOutcropModels
    .map(vom => vom.cesiumAsset)
    .reduce<TilesetParams[]>((acc, cur) => {
      if (
        cur &&
        cur.tilesetToken?.token &&
        cur.tilesetUrlLocal &&
        cur.location
      ) {
        acc.push({
          transform: {
            location: Cartographic.fromDegrees(
              cur.location.longitude,
              cur.location.latitude,
              cur.location.height,
            ),
            orientation: new HeadingPitchRoll(
              cur.location.heading,
              cur.location.pitch,
              cur.location.roll,
            ),
          },
          assetToken: cur.tilesetToken.token,
          localPath: cur.tilesetUrlLocal,
        });
      }
      return acc;
    }, []);
}

function PlacementPageContextProvider({
  outcrop,
  children,
}: {
  outcrop: Outcrop;
  children: ReactNode;
}) {
  const availableTilesets = useMemo(() => initialTilesets(outcrop), [outcrop]);

  const [viewer, setViewer] = useState<Viewer | null>(null);
  const [visibleTilesets, setVisibleTilesets] = useState<TilesetParams[]>([
    ...availableTilesets,
  ]);
  const [placementMode, setPlacementMode] = useState<boolean>(false);
  const [selectedSO, setSelectedSO] = useState<SO | null>(null);
  const [eventHandler, setEventHandler] =
    useState<ScreenSpaceEventHandler | null>(null);
  const [currentPlacement, setCurrentPlacement] =
    useState<LatLngHeightHPR | null>(null);
  const [currentEntity, setCurrentEntity] = useState<Entity | null>(null);
  const [currentEntityType, setCurrentEntityType] =
    useState<CesiumPlacementType>(CesiumPlacementType.Point);
  const [imageSize, setImageSizeState] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });
  const [imageSrc, setImageSrcState] = useState<string>();
  const [cameraZoomLevel, setCameraZoomLevel] = useState<number>(0.0001);
  const [currentIcon, setCurrentIcon] = useState<string>();

  async function setImageSrc(src: string | undefined) {
    if (!src) {
      return undefined;
    }
    const img = new Image();
    if (src) {
      img.src = src;
      await img.decode();
      setImageSize({ width: img.width, height: img.height });
      setImageSrcState(src);
    }
  }

  function setImageSize({ width, height }: { width: number; height: number }) {
    if (currentEntity) {
      if (
        currentEntityType === CesiumPlacementType.Panel &&
        currentEntity.billboard
      ) {
        currentEntity.billboard.height = new ConstantProperty(height);
        currentEntity.billboard.width = new ConstantProperty(width);
      }
    }
    setImageSizeState({ width, height });
  }

  function flyToLocation(loc: CesiumLocationPartsFragment) {
    viewer?.scene.camera.flyTo({
      destination: Cartesian3.fromDegrees(
        loc.longitude,
        loc.latitude,
        loc.height,
      ),
      orientation: new HeadingPitchRoll(loc.heading, loc.pitch, loc.roll),
    });
  }

  function toggleTilesetVisibility(assetToken: string) {
    const isVisible = !!visibleTilesets.find(
      ts => ts.assetToken === assetToken,
    );

    setVisibleTilesets(prev => {
      if (isVisible) {
        return prev.filter(ts => ts.assetToken !== assetToken);
      }

      const tileset = availableTilesets.find(
        ts => ts.assetToken === assetToken,
      );
      if (tileset) {
        return [...prev, tileset];
      } else {
        return prev;
      }
    });
  }

  const addEntity =
    (viewer: Viewer) =>
    async (item: SO): Promise<Entity | null> => {
      if (item.placement?.location) {
        const position = {
          latitude: item.placement.location.latitude,
          longitude: item.placement.location.longitude,
          height: item.placement.location.height,
          heading: item.placement.location.heading,
          pitch: item.placement.location.pitch,
          roll: item.placement.location.roll,
        } satisfies LatLngHeightHPR;
        let picUrl = item.file?.signedUrl;
        if (!picUrl && item.pictures) {
          picUrl = item.pictures.at(0)?.file.signedUrl;
        }
        const itemId = `${item.__typename}-${item.id}`;
        if (viewer.entities.getById(itemId) === undefined && item.placement) {
          if (item.__typename && isIframeType(item) && item.url) {
            return await addInfoBoxIconPoint(
              viewer,
              itemId,
              item.url,
              position,
              item.name,
              true,
              item.placement.icon || iconDefaults[item.__typename],
              item.placement.panelWidth ?? undefined,
              item.placement.panelHeight ?? undefined,
            );
          } else if (picUrl && item.__typename) {
            return await addInfoBoxIconPoint(
              viewer,
              itemId,
              picUrl,
              position,
              item.name,
              false,
              item.placement.icon || iconDefaults[item.__typename],
              item.placement.panelWidth ?? undefined,
              item.placement.panelHeight ?? undefined,
            );
          } else {
            return addIconPoint(
              viewer,
              itemId,
              item.placement.icon || iconDefaults[item.__typename || 'Marker'],
              position,
              item.name,
            );
          }
        }
      }
      return null;
    };

  const createAllEntities = () => {
    if (viewer) {
      // viewer.entities.removeAll();
    }
    if (viewer) {
      const addToViewer = addEntity(viewer);
      outcrop.crossSections.forEach(addToViewer);
      outcrop.pictures.forEach(addToViewer);
      outcrop.facies.forEach(addToViewer);
      outcrop.sedimentaryLogs.forEach(addToViewer);
      outcrop.wellLogs.forEach(addToViewer);
      outcrop.production.forEach(addToViewer);
      outcrop.reservoirModels.forEach(addToViewer);
      outcrop.trainingImages.forEach(addToViewer);
      outcrop.variograms.forEach(addToViewer);
      outcrop.gigaPans.forEach(addToViewer);
      outcrop.miniModels.forEach(addToViewer);
      outcrop.photo360s.forEach(addToViewer);
      outcrop.videos.forEach(addToViewer);

      outcrop.studies.forEach(study => {
        study.crossSections.forEach(addToViewer);
        study.pictures.forEach(addToViewer);
        study.facies.forEach(addToViewer);
        study.sedimentaryLogs.forEach(addToViewer);
        study.wellLogs.forEach(addToViewer);
        study.production.forEach(addToViewer);
        study.reservoirModels.forEach(addToViewer);
        study.trainingImages.forEach(addToViewer);
        study.variograms.forEach(addToViewer);
        study.gigaPans.forEach(addToViewer);
      });
    }
  };
  createAllEntities();

  function toggleSOVisibility(soId: string) {
    if (!viewer) return false;
    const result = toggleEntityVisibility(viewer, soId);
    return result?.isShowing ?? false;
  }

  function showPlacementMode(value: boolean, item: SO | null) {
    setPlacementMode(value);
    setSelectedSO(item);
    const defaultIcon = item && item.__typename ? iconDefaults[item.__typename] : "Marker";
    setCurrentIcon(defaultIcon);
    if (eventHandler) {
      eventHandler.destroy();
      setEventHandler(null);
    }
  }

  return (
    <PlacementPageContext.Provider
      value={{
        outcrop,
        toggleTilesetVisibility,
        setViewer,
        viewer,
        visibleTilesets,
        toggleSOVisibility,
        showPlacementMode,
        selectedSO,
        placementMode,
        setCurrentPlacement,
        currentPlacement,
        setEventHandler,
        eventHandler,
        setCurrentEntity,
        currentEntity,
        currentEntityType,
        setCurrentEntityType,
        flyToLocation,
        imageSize,
        setImageSize,
        imageSrc,
        setImageSrc,
        cameraZoomLevel,
        setCameraZoomLevel,
        currentIcon,
        setCurrentIcon,
      }}
    >
      {children}
    </PlacementPageContext.Provider>
  );
}

function usePlacementPageContext() {
  const ctx = useContext(PlacementPageContext);
  invariant(ctx, 'PlacementPageContext not initialized!');
  return ctx;
}

function UploadOutcropSOPlacementsPage() {
  const params = useParams();
  invariant(params.outcropId);
  const outcropId = parseInt(params.outcropId);

  useBreadcrumb(
    'routes/upload/model/outcrop/$outcropId/so-placements',
    'SO Placements',
  );

  const { data, loading } = useQuery(outcropSOPlacementsPageQuery, {
    variables: { id: outcropId },
  });

  const outcrop = data?.outcropGet;

  if (loading) return <SpinnerIcon />;
  if (!outcrop) return <NotFound />;

  return (
    <PlacementPageContextProvider outcrop={outcrop}>
      <PageInner outcrop={outcrop} />
    </PlacementPageContextProvider>
  );
}

function PageInner({ outcrop }: { outcrop: Outcrop }) {
  const {
    setViewer,
    viewer,
    visibleTilesets,
    showPlacementMode,
    placementMode,
    selectedSO,
    setCurrentPlacement,
    currentPlacement,
    setCurrentEntity,
    currentEntity,
    setCurrentEntityType,
    currentEntityType,
    eventHandler,
    setEventHandler,
    imageSize,
    setImageSrc,
    cameraZoomLevel,
    setCameraZoomLevel,
    currentIcon,
    setCurrentIcon,
  } = usePlacementPageContext();

  const { setIsFullWidth } = useFullWidthContext();

  const selectedSOPicture =
    selectedSO?.pictures?.at(0)?.file.signedUrl ?? selectedSO?.file?.signedUrl;

  const [globeVisibility, setGlobeVisibility] = useState(true);

  const [infoBoxHandler, setInfoBoxHandler] =
    useState<ScreenSpaceEventHandler>();

  useEffect(() => {
    return () => {
      setIsFullWidth(false);
    };
  }, [setIsFullWidth]);

  useEffect(() => {
    setImageSrc(selectedSOPicture);
  }, [selectedSOPicture, setImageSrc]);

  useEffect(() => {
    if (infoBoxHandler) {
      infoBoxHandler.destroy();
    }
    if (viewer) {
      const handler = enableEntityInfoBox(viewer, entity => {
        console.log('entity', entity);
      });
      setInfoBoxHandler(handler);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewer]);

  const [currentImageSize, setCurrentImageSize] = useState<{
    width: number;
    height: number;
  }>(imageSize);

  const [createSOPlacement, { loading: loadingCreate }] = useMutation(
    createSOPlacementMutation,
  );

  const [updateSOPlacement] = useMutation(updateSOPlacementMutation);
  const [updateSOPlacementLocation] = useMutation(
    updateSOPlacementLocationMutation,
  );
  const [updateSOPlacementDefaultCamera] = useMutation(
    updateSOPlacementDefaultCameraMutation,
  );

  async function createOrUpdateSO() {
    const camera = getCameraCartographic(viewer);
    if (!camera || !currentPlacement || !selectedSO || !selectedSO.__typename) {
      console.log('return early', camera, currentPlacement, selectedSO);
      showPlacementMode(false, null);
      return;
    }
    const refetchQueries: [
      PureQueryOptions<OutcropSoPlacementsPageQueryVariables>,
    ] = [
      {
        query: outcropSOPlacementsPageQuery,
        variables: { id: outcrop.id },
      },
    ];

    if (selectedSO.placement) {
      const vars: UpdateSupportingObjectPlacementMutationVariables = {
        id: selectedSO.placement.id,
        input: {
          panelHeight: currentImageSize.height,
          panelWidth: currentImageSize.width,
          type: currentEntityType,
          icon: currentIcon,
        },
      };
      const locationVars: UpdateSupportingObjectPlacementLocationMutationVariables =
        {
          id: selectedSO.placement.id,
          input: {
            location: {
              latitude: currentPlacement.latitude,
              longitude: currentPlacement.longitude,
              height: Math.floor(currentPlacement.height),
              heading: currentPlacement.heading,
              pitch: currentPlacement.pitch,
              roll: currentPlacement.roll,
            },
          },
        };
      const defaultCameraVars: UpdateSupportingObjectDefaultCameraMutationVariables =
        {
          id: selectedSO.placement.id,
          input: {
            location: {
              latitude: camera.latitude,
              longitude: camera.longitude,
              height: camera.height,
              heading: camera.heading,
              pitch: camera.pitch,
              roll: camera.roll,
            },
          },
        };
      updateSOPlacement({ variables: vars, refetchQueries });
      updateSOPlacementLocation({ variables: locationVars, refetchQueries });
      updateSOPlacementDefaultCamera({
        variables: defaultCameraVars,
        refetchQueries,
      });
    } else {
      type ParentType = NonNullable<typeof selectedSO>['__typename'];
      const pickIdIfType = (so: SO) => (property: ParentType) => {
        if (so.__typename === property) {
          return parseInt(so.id);
        }
        return null;
      };
      const pickId = pickIdIfType(selectedSO);

      const vars: CreateSupportingObjectPlacementMutationVariables = {
        input: {
          type: currentEntityType,
          icon: currentIcon,
          panelHeight: currentImageSize.height,
          panelWidth: currentImageSize.width,
          crossSectionId: pickId('CrossSection'),
          faciesSchemaId: pickId('FaciesSchema'),
          fieldPictureId: pickId('FieldPicture'),
          gigaPanId: pickId('GigaPan'),
          miniModelId: pickId('MiniModel'),
          photo360Id: pickId('Photo360'),
          pictureId: pickId('Picture'),
          productionId: pickId('Production'),
          reservoirModelId: pickId('ReservoirModel'),
          sedimentaryLogId: pickId('SedimentaryLog'),
          trainingImageId: pickId('TrainingImage'),
          variogramId: pickId('Variogram'),
          videoId: pickId('Video'),
          wellLogId: pickId('WellLog'),
        },
      };
      const res = await createSOPlacement({ variables: vars, refetchQueries });
      invariant(
        res.data?.supportingObjectPlacementCreate?.result?.id,
        'No id for new placement',
      );
      const placementVars: UpdateSupportingObjectPlacementLocationMutationVariables =
        {
          id: res.data?.supportingObjectPlacementCreate?.result?.id,
          input: {
            location: {
              latitude: currentPlacement.latitude,
              longitude: currentPlacement.longitude,
              height: Math.floor(currentPlacement.height),
              heading: currentPlacement.heading,
              pitch: currentPlacement.pitch,
              roll: currentPlacement.roll,
            },
          },
        };
      const cameraVars: UpdateSupportingObjectDefaultCameraMutationVariables = {
        id: res.data?.supportingObjectPlacementCreate?.result?.id,
        input: {
          location: {
            latitude: camera.latitude,
            longitude: camera.longitude,
            height: camera.height,
            heading: camera.heading,
            pitch: camera.pitch,
            roll: camera.roll,
          },
        },
      };
      const locationRes = await updateSOPlacementLocation({
        variables: placementVars,
        refetchQueries,
      });
      const cameraRes = await updateSOPlacementDefaultCamera({
        variables: cameraVars,
        refetchQueries,
      });
      console.log('Result: ', res, locationRes, cameraRes);
      console.log('saving');
    }
    showPlacementMode(false, null);
  }

  async function changeEntityType(
    type: CesiumPlacementType,
    item: SO,
    entity: Entity | null,
  ) {
    if (!viewer || !entity || !entity.position) {
      console.log('returned early', viewer, entity);
      return;
    }
    const currentEntityPlacement = entity.position.getValue(
      JulianDate.fromDate(new Date()),
    );
    const llh = convertFromCartesian(currentEntityPlacement);
    const entityId = entity.id;
    // @ts-ignore (only way to get entity children)
    entity._children.forEach(e => {
      viewer.entities.remove(e);
    });
    viewer.entities.remove(entity);
    const llhhpr: LatLngHeightHPR = { ...llh, heading: 0, pitch: 0, roll: 0 };
    // todo: fix this later when we actually differentiate between point and panel
    if (
      type === CesiumPlacementType.Panel ||
      type === CesiumPlacementType.Point
    ) {
      let picture = item.pictures?.at(0)?.file.signedUrl;
      if (!picture && item.file) {
        picture = item.file.signedUrl;
      }
      if (picture) {
        const e = await addInfoBoxIconPoint(
          viewer,
          entityId,
          picture,
          llhhpr,
          item.name,
          false,
          currentIcon || item.placement?.icon,
          item.placement?.panelWidth ?? undefined,
          item.placement?.panelHeight ?? undefined,
        );
        setCurrentEntityType(CesiumPlacementType.Point);
        // todo: use panel when its a real panel
        // setCurrentEntityType(SupportingObjectPlacementType.Panel);
        setCurrentEntity(e);

        if (eventHandler) {
          eventHandler.destroy();
        }
        const eh = await enableEntityClickPlacement(viewer, e, p => {
          setCurrentPlacement(p);
        });
        setEventHandler(eh);
      } else if (item.url) {
        const e = await addInfoBoxIconPoint(
          viewer,
          entityId,
          item.url,
          llhhpr,
          item.name,
          true,
          currentIcon || item.placement?.icon,
          item.placement?.panelWidth ?? undefined,
          item.placement?.panelHeight ?? undefined,
        );
        setCurrentEntityType(CesiumPlacementType.Point);
        // todo: use panel when its a real panel
        // setCurrentEntityType(SupportingObjectPlacementType.Panel);
        setCurrentEntity(e);

        if (eventHandler) {
          eventHandler.destroy();
        }
        const eh = await enableEntityClickPlacement(viewer, e, p => {
          setCurrentPlacement(p);
        });
        setEventHandler(eh);
      } else {
        setCurrentEntityType(CesiumPlacementType.Point);
        const e = await addIconPoint(
          viewer,
          entityId,
          currentIcon || item.placement?.icon,
          llhhpr,
          item.name,
        );
        setCurrentEntity(e);
        if (eventHandler) {
          eventHandler.destroy();
        }
        if (currentEntity) {
          const eh = await enableEntityClickPlacement(viewer, e, p => {
            setCurrentPlacement(p);
          });
          setEventHandler(eh);
        }
      }
    }
  }

  function handleMove(event: IJoystickUpdateEvent | null) {
    if (!event || !viewer) {
      return;
    }
    if (event.type === 'move' && event.x && event.y) {
      const changedY = event.y * cameraZoomLevel; //joystickSpeed
      const changedX = event.x * cameraZoomLevel;
      const currentPosition = currentEntity?.position?.getValue(
        JulianDate.now(),
      );
      if (currentPosition && currentEntity) {
        const converted = convertFromCartesian(currentPosition);
        const newPositionllhhpr = {
          latitude: converted.latitude + changedX,
          longitude: converted.longitude + changedY,
          height: converted.height,
          heading: 0,
          pitch: 0,
          roll: 0,
        } satisfies LatLngHeightHPR;
        currentEntity.position = new ConstantPositionProperty(
          Cartesian3.fromDegrees(
            newPositionllhhpr.longitude,
            newPositionllhhpr.latitude,
            newPositionllhhpr.height,
          ),
        );
        //@ts-ignore (_children is private but theres no other way to access entity children)
        currentEntity._children.forEach(e => {
          e.position = new ConstantPositionProperty(
            Cartesian3.fromDegrees(
              newPositionllhhpr.longitude,
              newPositionllhhpr.latitude,
              newPositionllhhpr.height,
            ),
          );
        });
        setCurrentPlacement(newPositionllhhpr);
      }
    }
  }

  function handleMoveHeight(event: IJoystickUpdateEvent | null) {
    if (!event || !viewer) {
      return;
    }
    if (event.type === 'move' && event.y) {
      const changedY = event.y * cameraZoomLevel * 10000; //joystickSpeed
      const currentPosition = currentEntity?.position?.getValue(
        JulianDate.now(),
      );
      if (currentPosition && currentEntity) {
        const converted = convertFromCartesian(currentPosition);
        const newPositionllhhpr = {
          latitude: converted.latitude,
          longitude: converted.longitude,
          height: converted.height + changedY,
          heading: 0,
          pitch: 0,
          roll: 0,
        } satisfies LatLngHeightHPR;
        currentEntity.position = new ConstantPositionProperty(
          Cartesian3.fromDegrees(
            newPositionllhhpr.longitude,
            newPositionllhhpr.latitude,
            newPositionllhhpr.height,
          ),
        );
        //@ts-ignore (_children is private but theres no other way to access entity children)
        currentEntity._children.forEach(e => {
          e.position = new ConstantPositionProperty(
            Cartesian3.fromDegrees(
              newPositionllhhpr.longitude,
              newPositionllhhpr.latitude,
              newPositionllhhpr.height,
            ),
          );
        });
        setCurrentPlacement(newPositionllhhpr);
      }
    }
  }

  function cameraCallback(viewer: Viewer) {
    const dist = getCurrentZoomDistance(viewer);
    if (!dist) {
      setCameraZoomLevel(0.00001);
    } else {
      if (dist < 250) {
        setCameraZoomLevel(0.00001);
      } else if (dist < 500) {
        setCameraZoomLevel(0.000025);
      } else if (dist < 1000) {
        setCameraZoomLevel(0.00005);
      } else if (dist < 2000) {
        setCameraZoomLevel(0.0001);
      }
    }
  }

  async function changeIcon(icon: string, item: SO, entity: Entity | null) {
    setCurrentIcon(icon);
    if (!viewer || !entity || !entity.position) {
      console.log('returned early', viewer, entity);
      return;
    }
    const currentEntityPlacement = entity.position.getValue(
      JulianDate.fromDate(new Date()),
    );
    const llh = convertFromCartesian(currentEntityPlacement);
    const entityId = entity.id;
    // @ts-ignore (only way to get entity children)
    entity._children.forEach(e => {
      viewer.entities.remove(e);
    });
    viewer.entities.remove(entity);
    const llhhpr: LatLngHeightHPR = { ...llh, heading: 0, pitch: 0, roll: 0 };
    let picture = item.pictures?.at(0)?.file.signedUrl;
    if (!picture && item.file) {
      picture = item.file.signedUrl;
    }
    if (picture) {
      const e = await addInfoBoxIconPoint(
        viewer,
        entityId,
        picture,
        llhhpr,
        item.name,
        false,
        icon,
        item.placement?.panelWidth ?? undefined,
        item.placement?.panelHeight ?? undefined,
      );
      setCurrentEntityType(CesiumPlacementType.Point);
      // todo: use panel when its a real panel
      // setCurrentEntityType(SupportingObjectPlacementType.Panel);
      setCurrentEntity(e);

      if (eventHandler) {
        eventHandler.destroy();
      }
      const eh = await enableEntityClickPlacement(viewer, e, p => {
        setCurrentPlacement(p);
      });
      setEventHandler(eh);
    } else if (item.url) {
      const e = await addInfoBoxIconPoint(
        viewer,
        entityId,
        item.url,
        llhhpr,
        item.name,
        true,
        icon,
        item.placement?.panelWidth ?? undefined,
        item.placement?.panelHeight ?? undefined,
      );
      setCurrentEntityType(CesiumPlacementType.Point);
      // todo: use panel when its a real panel
      // setCurrentEntityType(SupportingObjectPlacementType.Panel);
      setCurrentEntity(e);

      if (eventHandler) {
        eventHandler.destroy();
      }
      const eh = await enableEntityClickPlacement(viewer, e, p => {
        setCurrentPlacement(p);
      });
      setEventHandler(eh);
    } else {
      const e = await addIconPoint(viewer, entityId, icon, llhhpr, item.name);
      setCurrentEntity(e);
      if (eventHandler) {
        eventHandler.destroy();
      }
      const eh = await enableEntityClickPlacement(viewer, e, p => {
        setCurrentPlacement(p);
      });
      setEventHandler(eh);
    }
  }

  return (
    <div className="grid lg:grid-cols-4 gap-6">
      <div className="max-h-screen overflow-auto">
        <Sidebar outcrop={outcrop} />
      </div>
      <div className="lg:col-span-3">
        <CesiumViewer
          sendCesiumViewer={setViewer}
          initialTilesets={visibleTilesets}
          showGlobe={globeVisibility}
          terrainProvider={TerrainProvider.World}
          cameraCallback={cameraCallback}
          infoBox={true}
        />
        <Button
          onClick={() => {
            setIsFullWidth(prev => !prev);
          }}
          color="primary"
          size="md"
          className="gap-1 my-1"
        >
          Toggle Immersive Mode
        </Button>
        <Button
          onClick={() => {
            setGlobeVisibility(prev => !prev);
          }}
          color="primary"
          size="md"
          className="gap-1 my-1 mx-1"
        >
          Toggle Globe
        </Button>
        {placementMode && selectedSO && (
          <>
            <Heading level={3}>Place {selectedSO.name}</Heading>
            <div className="grid lg:grid-cols-2 gap-6">
              {selectedSOPicture && (
                <div>
                  <img
                    src={selectedSOPicture}
                    className="max-w-full h-auto"
                    alt={selectedSO.file?.name ?? 'Picture'}
                  />
                </div>
              )}

              <div>
                <p>Alt+Click to place Supporting Object</p>
                <p>Saving will save both placement and camera view</p>
                <label className="label">
                  <input
                    type="radio"
                    className="radio"
                    name="type"
                    defaultChecked
                    onChange={() => {
                      changeEntityType(
                        CesiumPlacementType.Point,
                        selectedSO,
                        currentEntity,
                      );
                    }}
                  />
                  Point
                </label>
                <label className="label">
                  <input
                    type="radio"
                    className="radio"
                    name="type"
                    onChange={() => {
                      changeEntityType(
                        CesiumPlacementType.Panel,
                        selectedSO,
                        currentEntity,
                      );
                    }}
                  />
                </label>
                <div className="flex items-center">
                  <IconSelector
                    value="icon"
                    onChange={icon => {
                      changeIcon(icon, selectedSO, currentEntity);
                    }}
                  />
                </div>
                <div className="items-center text-center my-2 mx-auto flex">
                  <SignalJoystick
                    size={100}
                    label={'Lat/Lng'}
                    signalRate={50}
                    callback={handleMove}
                    throttle={50}
                  />
                  <SignalJoystick
                    label={'Height'}
                    size={100}
                    signalRate={50}
                    callback={handleMoveHeight}
                    throttle={50}
                    controlPlaneShape={JoystickShape.AxisY}
                    baseShape={JoystickShape.Square}
                    stickShape={JoystickShape.Square}
                  />
                </div>

                {/* todo: update this after thumbnail */}
                {currentEntityType === CesiumPlacementType.Panel && (
                  <div className="text-center form-control">
                    <label className="label">
                      <span className="label-text">Panel Height (m)</span>
                    </label>
                    <input
                      name="height"
                      type="number"
                      id="panel-height"
                      placeholder="height"
                      className="input"
                      value={currentImageSize.height || imageSize.height}
                      onChange={v => {
                        const newHeight = v.target.value
                          ? parseInt(v.target.value)
                          : 0;
                        const newWidth =
                          newHeight === 0
                            ? 0
                            : (imageSize.width / imageSize.height) * newHeight;
                        setCurrentImageSize({
                          width: newWidth,
                          height: newHeight,
                        });
                        setBillboardSize(
                          viewer,
                          currentEntity,
                          newWidth,
                          newHeight,
                        );
                      }}
                    />
                    <label className="label">
                      <span className="label-text">Panel Width (m)</span>
                    </label>
                    <input
                      name="width"
                      type="number"
                      id="panel-width"
                      placeholder="width"
                      className="input"
                      value={currentImageSize.width || imageSize.width}
                      onChange={v => {
                        const newWidth = v.target.value
                          ? parseInt(v.target.value)
                          : 0;
                        const newHeight =
                          newWidth === 0
                            ? 0
                            : (imageSize.height / imageSize.width) * newWidth;
                        setCurrentImageSize({
                          width: newWidth,
                          height: newHeight,
                        });
                        setBillboardSize(
                          viewer,
                          currentEntity,
                          newWidth,
                          newHeight,
                        );
                      }}
                    />
                  </div>
                )}

                <div className="space-x-1">
                  <Button
                    type="submit"
                    onClick={createOrUpdateSO}
                    disabled={loadingCreate}
                    color="primary"
                  >
                    Save
                  </Button>
                  <Button
                    type="button"
                    color="ghost"
                    onClick={() => {
                      showPlacementMode(false, null);
                      if (currentEntity && viewer) {
                        //@ts-ignore (_children is private but theres no other way to access entity children)
                        currentEntity._children.forEach(e => {
                          viewer.entities.remove(e);
                        });
                        viewer.entities.remove(currentEntity);
                      }
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

const filterByOutcropTag = (outcropId: number) => (so: SO) =>
  so.outcropTagId === outcropId;

const studyHasPlaceableContent = (outcropId: number) => (study: Study) => {
  const soFilter = filterByOutcropTag(outcropId);
  const hasPicture = (so: SO) => (so.pictures?.length ?? 0) > 0;

  const picture = study.pictures.find(soFilter);
  if (picture) return true;
  const cs = study.crossSections.filter(soFilter).find(hasPicture);
  if (cs) return true;
  const facies = study.facies.filter(soFilter).find(hasPicture);
  if (facies) return true;
  const sl = study.sedimentaryLogs.filter(soFilter).find(hasPicture);
  if (sl) return true;
  const wl = study.wellLogs.filter(soFilter).find(hasPicture);
  if (wl) return true;
  const p = study.production.filter(soFilter).find(hasPicture);
  if (p) return true;
  const rm = study.reservoirModels.filter(soFilter).find(hasPicture);
  if (rm) return true;
  const ti = study.trainingImages.filter(soFilter).find(hasPicture);
  if (ti) return true;
  const v = study.variograms.filter(soFilter).find(hasPicture);
  if (v) return true;
  const gp = study.gigaPans.filter(soFilter).find(hasPicture);
  if (gp) return true;

  return false;
};

function Sidebar({ outcrop }: { outcrop: Outcrop }) {
  const outcropId = parseInt(outcrop.id);
  const studies = R.pipe(
    () => outcrop.studies,
    R.uniqBy(study => study.id),
    R.sortBy(study => study.name ?? ''),
    R.filter(studyHasPlaceableContent(outcropId)),
  )();

  const voms = outcrop.virtualOutcropModels;

  return (
    <div className="space-y-4">
      {!!voms.length && (
        <ExpandableSection heading="Virtual Outcrops">
          <div className="space-y-1">
            {voms.map(vom => (
              <VomItem key={`vom-${vom.id}`} vom={vom} />
            ))}
          </div>
        </ExpandableSection>
      )}

      <OutcropSection outcrop={outcrop} />

      {!!studies.length && (
        <ExpandableSection heading="Studies">
          <div className="space-y-2">
            {studies.map(study => (
              <StudySection
                key={study.id}
                outcropId={outcropId}
                study={study}
              />
            ))}
          </div>
        </ExpandableSection>
      )}
    </div>
  );
}

function ExpandableSection({
  heading,
  children,
}: {
  heading: ReactNode;
  children: ReactNode;
}) {
  const [expanded, setExpanded] = useState(true);

  return (
    <div>
      <button
        type="button"
        onClick={() => setExpanded(!expanded)}
        className="w-full flex justify-between items-center pr-2"
      >
        <div className="grow shrink-0 text-left max-w-full px-2">
          {typeof heading === 'string' ? (
            <Heading level={3}>{heading}</Heading>
          ) : (
            heading
          )}
        </div>
        <div className="shrink-0 ">
          <ExpandedIcon expanded={expanded} />
        </div>
      </button>

      {expanded && children}
    </div>
  );
}

function OutcropSection({ outcrop }: { outcrop: Outcrop }) {
  const outcropId = parseInt(outcrop.id);
  return (
    <div className="border border-slate-100 p-2">
      <Heading level={4} className="sticky top-0 mt-0 bg-white">
        {outcrop.name}
        <ItemLink to={uploadOutcropUpdateRoute(outcropId)} />
      </Heading>
      <div className="space-y-2">
        <SOSection label="Pictures" items={outcrop.pictures} />
        <SOSection label="Cross Sections" items={outcrop.crossSections} />
        <SOSection label="Facies" items={outcrop.facies} />
        <SOSection label="Sedimentary Logs" items={outcrop.sedimentaryLogs} />
        <SOSection label="Well Logs" items={outcrop.wellLogs} />
        <SOSection label="Production" items={outcrop.production} />
        <SOSection label="Reservoir Models" items={outcrop.reservoirModels} />
        <SOSection label="Training Images" items={outcrop.trainingImages} />
        <SOSection label="Variograms" items={outcrop.variograms} />
        <SOSection label="Giga Pans" items={outcrop.gigaPans} />
        <SOSection
          label="Field Pictures"
          items={outcrop.fieldPictures.map(fp => ({
            ...fp,
            id: fp.id,
            name: fp.file.name ?? `Field Picture ${fp.id}`,
          }))}
        />
        <SOSection label="Mini-Models" items={outcrop.miniModels} />
        <SOSection label="360 Photos" items={outcrop.photo360s} />
        <SOSection label="Videos" items={outcrop.videos} />
      </div>
    </div>
  );
}

function StudySection({
  outcropId,
  study,
}: {
  outcropId: number;
  study: Study;
}) {
  const studyId = parseInt(study.id);
  const soFilter = filterByOutcropTag(outcropId);

  return (
    <div className="border border-slate-100 p-2">
      <ExpandableSection
        heading={
          <div className="w-full">
            <Heading
              level={4}
              className="text-black sticky top-0 bg-white truncate max-w-full"
            >
              {study.name}
            </Heading>
            <div className="text-sm">
              {[study.dataHistory?.date, study.dataHistory?.collectedBy].join(
                ' - ',
              )}
              <ItemLink to={studyRoute(studyId)} />
            </div>
          </div>
        }
      >
        <div className="space-y-2">
          <SOSection label="Pictures" items={study.pictures.filter(soFilter)} />
          <SOSection
            label="Cross Sections"
            items={study.crossSections.filter(soFilter)}
          />
          <SOSection label="Facies" items={study.facies.filter(soFilter)} />
          <SOSection
            label="Sedimentary Logs"
            items={study.sedimentaryLogs.filter(soFilter)}
          />
          <SOSection
            label="Well Logs"
            items={study.wellLogs.filter(soFilter)}
          />
          <SOSection
            label="Production"
            items={study.production.filter(soFilter)}
          />
          <SOSection
            label="Reservoir Models"
            items={study.reservoirModels.filter(soFilter)}
          />
          <SOSection
            label="Training Images"
            items={study.trainingImages.filter(soFilter)}
          />
          <SOSection
            label="Variograms"
            items={study.variograms.filter(soFilter)}
          />
          <SOSection
            label="Giga Pans"
            items={study.gigaPans.filter(soFilter)}
          />
        </div>
      </ExpandableSection>
    </div>
  );
}

function SOSection({ label, items }: { label: string; items: SO[] }) {
  if (!items.length) return null;

  const sortedItems = R.sortBy(item => item.name.toLowerCase(), items);

  return (
    <div>
      <div className="text-xs text-muted">{label}</div>
      <div className="space-y-1">
        {sortedItems.map(item => (
          <SOItem key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
}

function SOItem({ item }: { item: SO }) {
  const [isVisible, setIsVisible] = useState(true);
  const {
    toggleSOVisibility,
    showPlacementMode,
    viewer,
    eventHandler,
    setEventHandler,
    setCurrentPlacement,
    setCurrentEntity,
    flyToLocation,
  } = usePlacementPageContext();

  const [isHovered, setIsHovered] = useState(false);
  const hasPlacement = !!item.placement;

  function handleToggleVisibility() {
    const nextVisible = toggleSOVisibility(`${item.__typename}-${item.id}`);
    setIsVisible(nextVisible);
  }

  async function showUnplacedItem(viewer: Viewer | null, item: SO) {
    if (!viewer) {
      return;
    }
    if (eventHandler) {
      eventHandler.destroy();
    }
    const defaultIcon = item && item.__typename ? iconDefaults[item.__typename] : "Marker";
    const e = await addIconPoint(
      viewer,
      `${item.__typename}-${item.id}`,
      defaultIcon,
      { latitude: 0, longitude: 0, height: 0, heading: 0, pitch: 0, roll: 0 },
      item.name,
    );
    setCurrentEntity(e);
    const eh = await enableEntityClickPlacement(viewer, e, p => {
      setCurrentPlacement(p);
    });
    setEventHandler(eh);
  }

  async function editPlacement(item: SO) {
    const entityId = `${item.__typename}-${item.id}`;
    const e = viewer?.entities.getById(entityId);
    if (viewer && e) {
      if (eventHandler) {
        eventHandler.destroy();
      }
      setCurrentEntity(e);
      const currentEntityPosition = e.position?.getValue(
        new JulianDate(Date.now()),
      );
      if (currentEntityPosition) {
        setCurrentPlacement(convertCartesian3Tollhhpr(currentEntityPosition));
      }
      const eh = await enableEntityClickPlacement(viewer, e, p => {
        setCurrentPlacement(p);
      });
      setEventHandler(eh);
    }
  }

  function handleFlyTo() {
    if (!item.placement?.defaultCamera) return;
    flyToLocation(item.placement.defaultCamera);
  }

  return (
    <div
      className="flex justify-between gap-2 w-full items-start"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <label
        className={cn(
          'label items-start border-l-2 border-slate-200 border-dotted grow pt-1',
          {
            truncate: !isHovered,
            'text-slate-800 pl-2.5': hasPlacement,
            'text-slate-400 pl-8': !hasPlacement,
          },
        )}
      >
        {hasPlacement && (
          <input
            type="checkbox"
            className="checkbox checkbox-xs"
            checked={hasPlacement && isVisible}
            onChange={handleToggleVisibility}
          />
        )}

        <div
          className={cn('w-full', {
            truncate: !isHovered,
          })}
        >
          {item.name}
        </div>
      </label>

      <div className="shrink whitespace-nowrap">
        {item.placement && (
          <>
            <Button type="button" color="ghost" size="xs" onClick={handleFlyTo}>
              <FontAwesomeIcon icon={faVideoCamera} />
            </Button>
            <Button
              type="button"
              color="ghost"
              size="xs"
              onClick={() => {
                showPlacementMode(true, item);
                editPlacement(item);
              }}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </Button>
            <SOPlacementStatusModal placement={item.placement} />
          </>
        )}
        {!hasPlacement && (
          <Button
            type="button"
            color="primary"
            size="xs"
            onClick={() => {
              showPlacementMode(true, item);
              showUnplacedItem(viewer, item);
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        )}
      </div>
    </div>
  );
}

function VomItem({ vom }: { vom: Vom }) {
  const { flyToLocation } = usePlacementPageContext();

  const token = vom.cesiumAsset?.tilesetToken?.token;
  const camera = vom.cesiumAsset?.defaultCamera;

  function handleFlyTo() {
    if (!camera) return;
    flyToLocation(camera);
  }

  return (
    <div className="flex justify-between gap-6 w-full">
      <div
        className={cn(
          'self-center ml-2 pl-2 border-l border-slate-200 border-dotted text-base max-w-full leading-4',
          {
            'text-slate-800': !!token,
            'text-slate-400': !token,
          },
        )}
      >
        {vom.name}
      </div>

      <div className="text-right">
        {token && camera && (
          <Button type="button" color="ghost" size="xs" onClick={handleFlyTo}>
            <FontAwesomeIcon icon={faVideoCamera} />
          </Button>
        )}
      </div>
    </div>
  );
}

function ItemLink({ to }: { to: LinkProps['to'] }) {
  return (
    <Link
      to={to}
      target="_blank"
      className="text-muted hover:text-primary ml-1 text-sm"
    >
      <FontAwesomeIcon icon={faExternalLink} />
    </Link>
  );
}

export default function V4Wrapper() {
  return (
    <ApolloProviderV4>
      <UploadOutcropSOPlacementsPage />
    </ApolloProviderV4>
  );
}
